import { createTheme } from '@material-ui/core/styles'
const whrepoTheme = createTheme({
	palette: {
		primary: {
			//main: '#1fd8cd',0DD9C8
			main: '#1F1F1F',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#d81f58',
			contrastText: '#ffffff',
		},
		// error: {

		// },
		// warning:{

		// },
		// info:{

		// },
		// success:{

		// },

	},
  });
  export default whrepoTheme;