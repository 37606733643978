import React from 'react'
import { Helmet } from 'react-helmet'

export default function AboutSEO() {
  return (
        <Helmet>
            <title>About &#8226; Wheels Repository</title>
            <meta name="description" content="Wheels Repository is an online marketplace to buy and sell vehicles connecting car buyers and car dealers or sellers from all over Nepal." />
            <link rel="canonical" href="https://whrepo.com/about/" />
        </Helmet>
  )
}