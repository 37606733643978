import * as api from '../api/customerApi';

export default function LogOut (props){
     
     const lUser = window.localStorage.getItem("user");

     const sUser = window.sessionStorage.getItem("user");
     let user = '';
     if(lUser){
          user = lUser;
     }
     
     if(sUser){
          user = sUser;
     }
     api.logOut({'user':user}).then((response)=> {
          
               window.localStorage.removeItem("user");
               window.localStorage.removeItem("token");
               
               window.sessionStorage.removeItem("user");
               window.sessionStorage.removeItem("token");
               document.location='/';
   
     });
     
    
}

