import axios from './axiosInstance'
//import {BASE_URL} from '../utils';
//const base_url = BASE_URL;
//const base_url = `http://localhost:8000`

export function getCustomers(pageNumber, sorted_column, order) {
    return axios.get(`/api/customers?page=${pageNumber}&column=${sorted_column}&order=${order}`)
}

export function getAcitveCustomers(pageNumber, sorted_column, order) {
    return axios.get(`/api/getActivebodies?page=${pageNumber}&column=${sorted_column}&order=${order}`)
}

export function addCustomer(values) {
    return axios.post('/api/customers',values)
}

export function deleteCustomer(customerId) {
    return axios.delete('/api/customers/'+ customerId)
}

export function updateCustomer(customerId, values) {
    return axios.put('/api/customers/'+ customerId, values)
}

// TOGGLE STATUS API
export function updateCustomerStatus (customerId, values) {
    return axios.put('/api/toggleCustomerStatus/'+ customerId, values)
}

export function getCustomer (customerId) {
    return axios.get('/api/customer/'+customerId)
}

export function login(values) {
    return axios.post('/api/customer/login',values)
}

export function logOut(values) {
    return axios.post('/api/customer/logout',values)
}

export function getTokens(values) {
    return axios.post('/api/customer/getTokens',values)
}

export function dealerSignUp(values) {
    return axios.post('/api/dealer/signup',values)
}

export function privateOwnerSignUp(values) {
    return axios.post('/api/privateOwner/signup',values)
}

export function forgotPassword(values) {
    return axios.post('/api/customer/forgotPassword',values)
}

export function resetPassword(id,token,data) {
    return axios.post('/api/customer/resetPassword',{id,token,password:data.password})
}

export function changePassword(values) {
    return axios.put('/api/customer/changePassword',{values})
}

export function verifyCustomer(id,token) {
    //$activation_url = URL::to('api/customer/activate?id='.$id.'&token='.$token);
    return axios.post('/api/customer/activate',{id,token})
}