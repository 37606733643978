// Footer
import React from 'react';
import { NavLink } from "react-router-dom";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import Copyright from '../../components/Copyright/Copyright';
//import { PlayCircleFilledWhite } from '@material-ui/icons';
const useStyles = makeStyles({
    title:{
        fontSize: '0.875em',
    },
    footer:{
        marginTop:"50px",
        background:"#c21c4f",
        color: '#ffffff',
        "& .MuiList-root":{
            color: 'rgba(255,255,255,0.7)',
        },
        "& .MuiListItem-root":{
            paddingTop: 0,
            paddingBottom: "4px",
            //color: "#ffffff",
            color: 'rgba(255,255,255,0.7)',
            "& :hover":{
                color: 'rgba(255,255,255,0.9)',
            }
        }

    },
   
});
const Footer = () => {
    const classes = useStyles();
    return(
        <footer className={classes.footer}>
            <Container >
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <Typography variant="h6" className={classes.title}>
                            About WHREPO
                        </Typography>
                        <List dense={true}>
                            <ListItem component={NavLink} to="/" disableGutters><ListItemText primary="Home" /></ListItem>
                            <ListItem component={NavLink} to="/about/" disableGutters><ListItemText primary="About" /></ListItem>
                            
                        </List>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography variant="h6" className={classes.title}>
                            Dealers/Private Owner
                        </Typography>
                        <List dense={true}>
                            <ListItem component={NavLink} to="/dealer-signup/" disableGutters><ListItemText primary="Dealer Sign Up" /></ListItem>
                            <ListItem component={NavLink} to="/sell-your-vehicle/" disableGutters><ListItemText primary="Private Owner Sign Up" /></ListItem>
                            <ListItem component="a" href="https://account.whrepo.com" disableGutters><ListItemText primary="Dealer/Seller Account Login" /></ListItem>
                            {/* <ListItem component={NavLink} to="/" disableGutters><ListItemText primary="Link" /></ListItem> */}
                        </List>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography variant="h6" className={classes.title}>
                            Quick Links
                        </Typography>
                        <List dense={true}>
                            <ListItem component={NavLink} to="/used-vehicles/" disableGutters><ListItemText primary="Used Vehicles" /></ListItem>
                            <ListItem component={NavLink} to="/new-vehicles/" disableGutters><ListItemText primary="New Vehicles" /></ListItem>
                            <ListItem component={NavLink} to="/dealers/" disableGutters><ListItemText primary="Dealers" /></ListItem>
                            <ListItem component={NavLink} to="/sell-your-vehicle/" disableGutters><ListItemText primary="Sell Vehicle" /></ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography variant="h6" className={classes.title}>
                            Help
                        </Typography>
                        <List dense={true}>
                            <ListItem component={NavLink} to="/contact/" disableGutters><ListItemText primary="Contact" /></ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Copyright />
                    
                
            </Container>
        </footer>
            
    )
}
export default Footer;