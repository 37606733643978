import React, { Component } from 'react'

import DealerDetailSEO from '../../components/SEO/DealerDetail'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import SortingBar from '../../components/InventoryListing/SortingBar/SortingBar'
import DealerVehicleListingItem from '../../components/InventoryListing/ListingItem/DealerVehicleListingItem'
import Footer from '../../components/Footer/Footer'

import * as dealerApi from '../../api/dealerApi'
import * as vechileApi from '../../api/vehicleApi'
import {NO_OF_ROWS} from '../../utils'
import {BACKEND_URL} from '../../utils'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import LinkIcon from '@material-ui/icons/Link'
import MailIcon from '@material-ui/icons/Mail'
import StoreIcon from '@material-ui/icons/Store'
import CardMedia from '@material-ui/core/CardMedia'
import CircularProgress from '@material-ui/core/CircularProgress'

import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../../components/InventoryListing/TablePaginationActions/TablePaginationActions'

import { withStyles } from '@material-ui/core/styles'
import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'
import vehicleListStyle from '../../assets/jss/components/vehicleListStyle'

const useStyles = vehicleListStyle;

class DealerDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            vehicles:[],
            dealer:{},
            sorted_column: 'id',
            order: 'desc',
            total:0,
            pageNumber:0,
            viewType:"List", //Module
            badges:[]
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleViewType = this.handleViewType.bind(this);
    }
    componentDidMount() {
        this.setState({isLoading:true});
        vechileApi.getBadgesList().then((response)=>{
            this.setState({badges:response.data,isLoading:false});
        })
        const params = this.props.match.params;
        if(params.slug && params.id && params.loc_id){
                dealerApi.getDealer(params.id, params.loc_id).then((response)=>{
                    this.setState({dealer:response.data.dealer,total:response.data.dealer.count,isLoading:false});
                })
                dealerApi.getDealerVehicles(1, params.id, params.loc_id, NO_OF_ROWS, this.state.sorted_column, this.state.order).then((response)=> {
                    if(response.data.success){
                        this.setState({vehicles:response.data.vehicles,isLoading:false});
                    }
                })
        }
        window.scrollTo(0, 0)
        
    }
    
    handleChangePage(event,page){
        this.setState({isLoading:true});
        dealerApi.getDealerVehicles(page+1,this.state.dealer.id, this.state.dealer.loc_id, NO_OF_ROWS,this.state.sorted_column,this.state.order).then((response)=> {
            if(response.data.success){
                this.setState({vehicles:response.data.vehicles, pageNumber:page, isLoading:false});
            }
            
        })
    }

    handleSortingBarSubmit(values) {
        let column = 'id';
        let order = 'desc';
        if(values === 'PA'){
            column = 'price';
            order = 'asc';
        }else if(values === 'PD'){
            column = 'price';
            order = 'desc';
        }else if(values === 'YA'){
            column = 'year';
            order = 'asc';
        }else if(values === 'YD'){
            column = 'year';
            order = 'desc';
        }else if(values === 'DD'){
            column = 'id';
            order = 'desc';
        }else{
            column = 'id';
            order = 'desc';
        }
        this.setState({ sorted_column: column,order: order });
        dealerApi.getDealerVehicles(1,this.state.dealer.id, this.state.dealer.loc_id, NO_OF_ROWS, column, order).then((response)=> {
            if(response.data.success){
                this.setState({vehicles:response.data.vehicles, isLoading:false});
            }
        })
    }

    handleViewType(type) {
        this.setState({viewType:type});
    }
    
    render(){
        const { classes } = this.props;
        let gridParentClass = '';
        if(this.state.viewType !== "List"){
            gridParentClass = 'wr-grid-listing';
        }
        let dealerAddress = this.state.dealer.address?this.state.dealer.address:"";
        dealerAddress += this.state.dealer.address2?", "+this.state.dealer.address2:"";
        dealerAddress += this.state.dealer.city?", "+this.state.dealer.city:"";
        //dealerAddress += "<br>";
        dealerAddress += this.state.dealer.province?", "+this.state.dealer.province:"";
        dealerAddress += this.state.dealer.postal_code?", "+this.state.dealer.postal_code:"";

        return(
            <ThemeProvider theme={whrepoTheme}>
                <DealerDetailSEO  dealer={this.state.dealer} />
                 <Header user={this.props.user} isAuthenticated={this.props.isAuthenticated}/>
                <main className={classes.main}>
                <Container>
                    <Breadcrumbs />
                    <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                        {this.state.dealer.name}
                    </Typography>
                    
                    
                    <Grid container spacing={3}>
                        
                        
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.paper} spacing={3}>
                                {!this.state.isLoading ? ( 
                                <Typography component="h4" color="inherit" variant="h5">
                                {this.state.dealer.count>1?this.state.dealer.count+" Vehicles":this.state.dealer.count+" Vehicle"}
                                </Typography>
                                ) : (
                                    <>
                                        <CircularProgress color="secondary" size="2rem"/>
                                    </>
                                  )}
                            </Paper>
                            
                            {!this.state.isLoading ? (
                            <>
                            <SortingBar handleSortBy={this.handleSortingBarSubmit.bind(this)} handleViewType={this.handleViewType}/>
                            <div className={classes.listContainer}>
                            <div className={`wr-listing ${gridParentClass}`}>
                                {
                                    this.state.vehicles.map((vehicle) => <DealerVehicleListingItem key={vehicle.id}
                                                                            vehicle={vehicle}
                                                                            viewType={this.state.viewType}
                                                                            badges={this.state.badges} 
                                                                            vType = {vehicle.condition}
                                                                            isLoading={this.state.isLoading}
                                                                            dealer={this.state.dealer.id}
                                                                            />)
                                }
                                
                            </div>
                            </div>
                            </>
                            ) : (
                                <Paper className={classes.paper} spacing={2}>
                                    <CircularProgress color="secondary" size="2rem"/>
                                </Paper>
                              )}
                                {this.state.total > NO_OF_ROWS ?
                                <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={NO_OF_ROWS}
                                page={this.state.pageNumber}
                                onChangePage={this.handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                //onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> : null
                            }
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <Paper className={`${classes.SidebarPaper} ${classes.listedBy}`}>
                         {this.state.dealer.name?<Typography component="h3" color="inherit" variant="h6" gutterBottom>{this.state.dealer.name}</Typography>:null}
                         {this.state.dealer?
                            <>
                            <List dense={true} className={classes.list}>
                            {this.state.dealer.website&&this.state.dealer.website!=="null"?<ListItem disableGutters><ListItemIcon><LinkIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={this.state.dealer.website} target="_blank">{this.state.dealer.website}</Link>} /></ListItem>:""}
                            {this.props.isAuthenticated?
                            <>
                            {this.state.dealer.contact_no?<ListItem disableGutters><ListItemIcon><PhoneIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"tel:"+ this.state.dealer.contact_no}>{this.state.dealer.contact_no}</Link>} /></ListItem>:null}
                            {this.state.dealer.email?<ListItem disableGutters><ListItemIcon><MailIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"mailto:"+ this.state.dealer.email}>{this.state.dealer.email}</Link>} /></ListItem>:null}
                            </>
                            :null}
                            {this.state.dealer && this.state.dealer.logo? <CardMedia className={classes.dealerLogo} image={`${BACKEND_URL}/storage/whrepo/logos/${this.state.dealer.logo}`} title={this.state.dealer && this.state.dealer.name?this.state.dealer.name:null} />:null}
                            {this.props.isAuthenticated?
                            <>
                            {this.state.dealer.location_name?<ListItem disableGutters><ListItemIcon><StoreIcon fontSize="small" /></ListItemIcon><ListItemText primary={this.state.dealer.location_name} /></ListItem>:null}
                            {dealerAddress?<ListItem className={classes.dealerAddress} disableGutters><ListItemIcon><LocationOnIcon fontSize="small" /></ListItemIcon><ListItemText primary={dealerAddress}  className={classes.dealerAddress}/></ListItem>:null}
                            </>
                            :null}
                            {!this.props.isAuthenticated?
                            <>
                            <br />
                            <Typography variant="body2"><Link color="secondary" href="/login">Log in</Link> or <Link color="secondary" href="/register">Register</Link><br />to view contact information.</Typography>
                            </>
                            :null}
                            </List>
                            </>
                            :null}
                        </Paper>
                        </Grid>
                    </Grid>
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )

    }
    
    
};

export default withStyles(useStyles)(DealerDetailPage);