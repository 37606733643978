
const vehicleDetailStyle = theme => ({
    paper:{
        padding: theme.spacing(2),
        "& .wr-attributes-col":{
            fontSize:14,
            lineHeight:1.5,
        }
    },
    listingDetail:{
        "& .MuiTypography-h3":{
            lineHeight:1,
            margin:0,
        },
        "& .wr-action-links":{
            marginBottom: theme.spacing(2),
        },
        "& .wr-vehicle-gallery":{
            display:"block", 
            overflow: "hidden",
        }
    },
    listedBy:{
        marginTop: "1.5rem",
        "& .MuiTypography-h6":{
            lineHeight:1,
        }
    },
    listedByChip:{
        marginBottom: theme.spacing(2)
    },
    list:{
        lineHeight: 1,
        "& .MuiListItemText-root":{
          marginTop:0,
          marginBottom:0,
          color:"rgba(0, 0, 0, 0.54)",
        },
        "& .MuiListItemIcon-root":{
          minWidth: 35
        }
      },
      dealerLogo: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: "contain",
        border: "1px solid #e5e5e5",
      },
      dealerAddress:{
        alignItems: "flex-start",
          "& .MuiListItemText-root":{
            whiteSpace: "pre-wrap",
          },
      },
    price:{ 
        textAlign: "right",
       "& del,& ins":{
            display: "block"
        },
        "& del":{
            textDecoration: "line-through",
            color:"grey",
            "& .wr-price-amount":{
                fontSize:24,
                lineHeight: 1,
                fontWeight:700,
            },
        },
        "& ins":{
            textDecoration: "none"
        },
        "& .wr-price-amount":{
            fontSize:32,
            lineHeight: 1,
            fontWeight:700
        },
        "& .wr-tax-info":{
            fontSize: 12,
            lineHeight: 1,
        },
        "& .wr-request-price":{
            fontSize: 18,
            lineHeight:1,
        }
    },
    actionButtons:{
        display:"flex",
        flexWrap:"wrap",
        flexDirection:"row",
        justifyContent:"space-between",
        "& a":{
            color: theme.palette.text.secondary,
            textDecoration: "none",
            "&:hover":{
                color: theme.palette.text.secondary,
                textDecoration: "none", 
            }
        }
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //flex:"1 1 0",
        "&:last-child":{
            marginRight:0, 
        }
    },
    vehicleInfoTabs:{
        borderRadius:4,
        overflow:"hidden",
        marginTop: theme.spacing(2),
    },
    financeCalc:{
    marginTop: theme.spacing(2),
    "& .MuiTypography-h6":{
        lineHeight: 1,
    }
    },
    "@media only screen and (max-width: 1279px)":{
        listingDetail:{
            "& .MuiTypography-h3":{
                fontSize:"2rem",
            },
            "& .MuiTypography-h6":{
                fontSize:"1rem",
            }
        }
    },
    "@media only screen and (max-width: 599px)":{
        listingDetail:{
            "& .MuiTypography-h3":{
                fontSize:"1.25rem",
            },
            "& .MuiTypography-h6":{
                fontSize:"0.875rem",
            }
        },
        price:{
            "& .wr-price-amount":{
                fontSize:"1.25rem",
            },
            "& del .wr-price-amount":{
                fontSize:"1rem",
            },
        }
    },
    "@media only screen and (max-width: 439px)":{
        listingDetail:{
            "& .MuiTypography-h3":{
                fontSize:"1rem",
            },
            "& .MuiTypography-h6":{
                fontSize:"0.75rem",
            }
        },
        price:{
            "& .wr-price-amount":{
                fontSize:"1rem",
            },
            "& del .wr-price-amount":{
                fontSize:"0.75rem",
            },
            "& .wr-tax-info":{
                fontSize: "0.625rem",
            },
            "& .wr-request-price":{
                fontSize: "1rem",
            }
        }
    }	
});
  export default vehicleDetailStyle;