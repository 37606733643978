import React from 'react'
import { Link } from 'react-router-dom'

import NotFoundSEO from '../../components/SEO/NotFound'
import Header from '../../components/Header/Header'
import SimpleBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

const useStyles = makeStyles(theme => ({
    main:{
        marginTop: 100,
        minHeight: "calc(100vh - 341px)",
    },
    textCenter:{
        textAlign:"center"
    }
}));
const NotFound = (props) => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
        return(
            <ThemeProvider theme={whrepoTheme}>
                <NotFoundSEO />
                <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
                <main className={classes.main}>
                    <Container>
                        <SimpleBreadcrumbs page="404"/>
                        <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                            Page not found
                        </Typography>
                        <Typography className={classes.textCenter}>
                            <Link to="/">Go to Home </Link>
                        </Typography>
                    </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )
    }

export default NotFound;