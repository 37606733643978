// Main
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

//whrepo pages
import Home from '../views/HomePage/HomePage';
import About from '../views/AboutPage/AboutPage';
import Contact from '../views/ContactPage/ContactPage';
import InventoryListing from '../views/InventoryListingPage/InventoryListing';
//import VehicleListing from '../views/InventoryListingPage/VehicleListing';
import UsedVehicleListing from '../views/InventoryListingPage/UsedVehicleListing';
import NewVehicleListing from '../views/InventoryListingPage/NewVehicleListing';

import DealerDetailPage from '../views/DealerDetailPage/DealerDetailPage';
import InventoryDetail from '../views/InventoryDetailPage/InventoryDetailPage';
import Register from '../views/RegisterPage/RegisterPage';
import DealerSignUp from '../views/DealerSignUpPage/DealerSignUpPage';
import PrivateOwnerSignUp from '../views/PrivateOwnerSignUpPage/PrivateOwnerSignUpPage';
import Login from '../views/LoginPage/LoginPage';
import LogOut from './LogOut';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import DealerListing from '../views/DealerListingPage/DealerListingPage';
import NotFoundPage from '../views/NotFoundPage/NotFoundPage';
import ProfilePage from '../views/ProfilePage/ProfilePage';
import ActivationPage from '../views/RegisterPage/ActivationPage';

import Privacy from '../views/PrivacyPage/PrivacyPage';
import Terms from '../views/TermsPage/TermsPage';



import * as api from '../api/customerApi';

import MasterRoute from './masterRoute';


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:'',
            token:'',
            isAuthenticated:false,
            loggedUser: {},
            isLoading:true
        }
    }
    componentDidMount() {
        this.isAuthenticated(); 
        //this.setState({isLoading:false});
    }

    isAuthenticated (){
        const lUser = window.localStorage.getItem('user');
        const lToken = window.localStorage.getItem('token');

        const sUser = window.sessionStorage.getItem('user');
        const sToken = window.sessionStorage.getItem('token');
        if(sUser && sToken){
            api.getTokens({'user':sUser}).then((response)=> {
                //debugger;
                if(response.data.success && (sToken === response.data.s_token)){
                    this.setState({isAuthenticated:true,loggedUser:response.data.customer});  
                }else{
                    this.setState({isAuthenticated:false}); 
                }
            })
            //this.setState({isAuthenticated:true});
        }else if(lUser && lToken){
            api.getTokens({'user':lUser}).then((response)=> {
                //debugger;
                if(response.data.success && (lToken === response.data.r_token)){
                    this.setState({isAuthenticated:true,loggedUser:response.data.customer});  
                }else{
                    this.setState({isAuthenticated:false}); 
                }
            })
        }else{
            this.setState({isAuthenticated:false}); 
        }
                this.setState({isLoading:false});

        
    }
    render() {
        return (
	        <BrowserRouter>
                <Switch>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} exact path="/" component={Home}/>
                {/* <MasterRoute isAuthenticated={this.state.isAuthenticated} path="/vehicles/:search?" component={VehicleListing}/> */}
                <MasterRoute user = {this.state.loggedUser} isLoading={this.state.isLoading} isAuthenticated={this.state.isAuthenticated} path="/used-vehicles/:search?" component={UsedVehicleListing}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/new-vehicles/:search?" component={NewVehicleListing}/>

                {/* <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} exact path="/dealer/:dealer" component={DealerDetailPage}/> */}
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} exact path="/dealer/:slug/:id/:loc_id" component={DealerDetailPage}/>

                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/inventory/:search?" component={InventoryListing}/>
                {/* <MasterRoute isAuthenticated={this.state.isAuthenticated} path="/vehicle/:slug/:id" component={InventoryDetail}/> */}
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/used-vehicle/:slug/:id" component={InventoryDetail}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/new-vehicle/:slug/:id" component={InventoryDetail}/>
                {/* <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/vehicle/dealer/:id/:vehicleid" component={InventoryDetail}/> */}

                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/about" component={About}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/contact" component={Contact}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/privacy" component={Privacy}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/terms" component={Terms}/>
                <MasterRoute isAuthenticated={this.state.isAuthenticated} path="/register" component={Register}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/dealer-signup" component={DealerSignUp}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/sell-your-vehicle" component={PrivateOwnerSignUp}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/dealers/:search?" component={DealerListing}/>
                <MasterRoute user = {this.state.loggedUser} isAuthenticated={this.state.isAuthenticated} path="/profile" component={ProfilePage}/>

                <Route path="/login" component={Login}/>
                <Route path="/logout" component={LogOut}/>
                <Route path="/forgot-password" component={ForgotPassword}/>
                <Route path="/reset-password/:id" component={ResetPassword}/>
                <Route path="/verify" component={ActivationPage}/>
                <Route path="/404" component={NotFoundPage} />
                <Route path="*" component={NotFoundPage} />
                {/* <Redirect to="/404" /> */}
                </Switch>
	       </BrowserRouter> 
        );
    }
}
export default Main;
