const vehicleListStyle = theme => ({
    main:{
        marginTop:100,
        "& .wr-grid-listing":{
            display: "flex",
            //background: "yellow",
            flexWrap: "wrap",
            width: "calc(100% + 16px)",
            margin: "-8px",
            boxSizing: "border-box",
           
        },
        "& .wr-grid-view":{
            width: "inherit",
            flexGrow:0,
            flexBasis: "25%",
            maxWidth:"25%",
            padding:"8px",
            margin:0,
            boxSizing: "border-box",
            //background:"pink",
            "& .MuiPaper-root":{
                margin:0,
                height:"100%",
            },
            "& .wr-vehicle-item .wr-vehicle-image img":{
                width:"100%",
            }
        },
    },
    "@media only screen and (max-width:799px)":{
        main:{
            "& .wr-grid-view":{
                flexBasis: "33.33%",
                maxWidth:"33.33%", 
                "& .wr-vehicle-item .wr-vehicle-image img":{
                    width:"100%",
                }
            }
        }
    },
    "@media only screen and (max-width:599px)":{
        main:{
            "& .wr-grid-view":{
                flexBasis: "50%",
                maxWidth:"50%", 
                "& .wr-vehicle-item .wr-vehicle-image img":{
                    width:"100%",
                }
            }
        }
    },
    paper: {
      padding: theme.spacing(2),
      //textAlign: 'center',
      color: theme.palette.text.secondary,
      marginBottom: '16px'
    },
    listContainer:{
    margin: "16px 0",
    },
    formControl: {
        margin: theme.spacing(3),
    },
    SidebarPaper: {
    padding: theme.spacing(2),
    overflow: "hidden"
    },
    listedBy:{
        "& .MuiTypography-h6":{
            lineHeight:1,
        }
    },
    list:{
        lineHeight: 1,
        "& .MuiListItemText-root":{
          marginTop:0,
          marginBottom:0,
          color:"rgba(0, 0, 0, 0.54)",
        },
        "& .MuiListItemIcon-root":{
          minWidth: 35
        },

      },
      dealerLogo: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: "contain",
        border: "1px solid #e5e5e5",
      },
      dealerAddress:{
        alignItems: "flex-start",
          "& .MuiListItemText-root":{
            whiteSpace: "pre-wrap",
          },
      },
      pagination:{
          "& .MuiPaginationItem-page.Mui-disabled":{
              display:"none",
          }
      }
});

export default vehicleListStyle;