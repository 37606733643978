import React from 'react'
import { Helmet } from 'react-helmet'

export default function LoginSEO() {
  return (
        <Helmet>
            <title>Log In &#8226; Wheels Repository</title>
            <meta name="description" content="Log In - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/login/" />
        </Helmet>
  )
}