import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    accordion:{
        color: theme.palette.text.secondary,
    },
    SidebarPaper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
        color: theme.palette.text.secondary,
        overflow: "hidden"
    },
    form:{
        width: "100%",
    },
    formControl: {
        marginBottom: theme.spacing(3),
        width:"100%"
    },
    formLabel:{
        fontSize: "1em",
        fontWeight: "600"
    },
  }));

function SidebarFilter(props){

    const classes = useStyles();

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [expanded, setExpanded] = React.useState('');

    const handlePanelChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
    
    const handleSelectChange = event => {
        let params = {};
        setValues(oldValues => ({
            ...oldValues,
            [event.target.name]: event.target.value,
            }));
        const dvalues = {...values,[event.target.name]: event.target.value};
        params = {'color':color,...dvalues};
        props.handleFilter(params);    
    };

    const [values, setValues] = React.useState({
        year: '',
        make: '',
        model:'',
        transmission:'',
        body:'',
        priceRange:'',
    });

    const [keyword, setKeyword] = React.useState();
    const [color, setColor] = React.useState([]);

    const handleColorCheckboxChange = (id,name) => event => {
        let params = {};
        if(event.target.checked){
            setColor(oldValues => ([
                ...oldValues,name
            ]));
            const dcolor = [...color,name];
            params = {...values,"color":dcolor};
        }else{
            if(color.length>0){
                let c = color.filter(function(item)
                {
                    return item !== name;
                });
                
                if(c !== undefined){
                    params = {...values,"color":c};
                    setColor(c);
                }
            }
        }      

        props.handleFilter(params);
    };

    const handleKeyword = (event) => {
        setKeyword(event.target.value);
      };

    const renderChecked = (name) => {
        if(Array.isArray(props.search_params.color) && (props.search_params.color.length > 0)){
            let result = props.search_params.color.find(item => {
                return item === name
              })
           
            if(result){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
        
    }
    
    React.useEffect(() => {   
        //debugger;     
        setValues({year:props.search_params.year,
                    make:props.search_params.make,
                    model:props.search_params.model,
                    transmission:props.search_params.transmission,
                    body:props.search_params.body,
                    priceRange:props.search_params.priceRange,
        });
    },[props.search_params.year,props.search_params.make,props.search_params.model,props.search_params.transmission,props.search_params.body,props.search_params.priceRange]);
    
    React.useEffect(() => {        
        setColor(props.search_params.color);
    },[props.search_params.color]);

    React.useEffect(() => {        
        setKeyword(props.keyword);
    },[props.keyword]);

    const filterForm = <form className={classes.form}> 
    <FormControl component="fieldset" className={classes.formControl}>
       <TextField 
           id="keyword"
           type="search" 
           label="Keyword" 
           value={keyword} 
           helperText="Enter Keyword to Search"
           onChange={handleKeyword}
           InputProps={{
               endAdornment: (
               <InputAdornment>
                   <IconButton 
                       aria-label="search dealer"
                       onClick={()=>props.handleSearch(keyword)}
                       //onMouseDown={handleMouseDownSearch}
                       size="small"
                   >
                   <SearchIcon />
                   </IconButton>
               </InputAdornment>
               )
           }}
           // InputLabelProps={{
           //     shrink: kword?true:false,
           //   }}
       />
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="priceRange">All Price Range</InputLabel>
       <Select
       value={values.priceRange}
       onChange={handleSelectChange}
       inputProps={{
           name: 'priceRange',
           id: 'priceRange',
       }}
       >   
{props.priceRanges.map((priceRange)=>{
                   return <MenuItem key={priceRange.id} value={priceRange.value}>{priceRange.label}</MenuItem>
               })}
        {/* 1>1 to 5 Lakh<
            2>5 to 10 Lakh<
            3>10 to 15 Lakh<
            4>15 to 20 Lakh<
            5>20 to 25 Lakh<
            6>25 to 30 Lakh<
            7>30 to 35 Lakh<
            8>35 to 50 Lakh<
            9>50 to 1 Crore<
            10>Above 1 Crore< */}


       {/* <MenuItem  value="1">1 to 5 Lakh</MenuItem>
       <MenuItem  value="2">5 to 10 Lakh</MenuItem>
       <MenuItem  value="3">10 to 15 Lakh</MenuItem>
       <MenuItem  value="4">15 to 20 Lakh</MenuItem>
       <MenuItem  value="5">20 to 25 Lakh</MenuItem>
       <MenuItem  value="6">25 to 30 Lakh</MenuItem>
       <MenuItem  value="7">30 to 35 Lakh</MenuItem>
       <MenuItem  value="8">35 to 50 Lakh</MenuItem>
       <MenuItem  value="9">50 to 1 Crore</MenuItem>
       <MenuItem  value="10">Above 1 Crore</MenuItem> */}
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="year-simple">All Years</InputLabel>
       <Select
       value={values.year}
       onChange={handleSelectChange}
       inputProps={{
           name: 'year',
           id: 'year-simple',
       }}
       >   
           {props.years.map((year)=>{
                   return <MenuItem key={year.year} value={year.year}>{year.year} ({year.count})</MenuItem>
               })}  
       
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="make-simple">All Makes</InputLabel>
       <Select
       value={values.make}
       onChange={handleSelectChange}
       inputProps={{
           name: 'make',
           id: 'make-simple',
       }}
       >   
           {props.makes.map((make)=>{
               return <MenuItem key={make.id} value={make.make_name}>{make.make_name} ({make.count})</MenuItem>
           })}     
       
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="model-simple">All Models</InputLabel>
       <Select
       value={values.model}
       onChange={handleSelectChange}
       inputProps={{
           name: 'model',
           id: 'model-simple',
       }}
       >   
           {props.models.map((model)=>{
               return <MenuItem key={model.id} value={model.model_name}>{model.model_name} ({model.count})</MenuItem>
           })}    
       
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="transmission-simple">All Transmissions</InputLabel>
       <Select
       value={values.transmission}
       onChange={handleSelectChange}
       inputProps={{
           name: 'transmission',
           id: 'transmission-simple',
       }}
       >   
       {props.transmissions.map((transmission)=>{
               return <MenuItem key={transmission.id} value={transmission.transmission_name}>{transmission.transmission_name} ({transmission.count})</MenuItem>
           })} 
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <InputLabel htmlFor="body-simple">All Body Styles</InputLabel>
       <Select
       value={values.body}
       onChange={handleSelectChange}
       inputProps={{
           name: 'body',
           id: 'body-simple',
       }}
       >
       {props.bodies.map((body)=>{
               return <MenuItem key={body.id} value={body.body_name}>{body.body_name} ({body.count})</MenuItem>
           })} 
       </Select>
   </FormControl>
   <FormControl component="fieldset" className={classes.formControl}>
       <FormLabel component="legend" className={classes.formLabel}>Color</FormLabel>
       <FormGroup row>
       {props.colors.map((color)=>{
                   return (
                       <FormControlLabel
                           control={<Checkbox value={color.color_name} checked={renderChecked(color.color_name)}/>}
                           label={color.color_name +" ("+color.count+")" } 
                           key={color.id}
                           onChange={handleColorCheckboxChange(color.id,color.color_name)}
                       />
                   )
                   
           })}
       
       </FormGroup>
   </FormControl>
</form>
    
    if(smallScreen){
        return(
            <>
                <Accordion expanded={expanded} className={classes.accordion} onChange={handlePanelChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography component="h2" color="inherit" variant="h6" gutterBottom>Refine your Search</Typography>
                    </AccordionSummary>
                    {!props.isLoading ? ( 
                    <AccordionDetails>
                    {filterForm}
                    </AccordionDetails>
                    ) : (
                    <AccordionDetails>
                        <CircularProgress color="secondary" />
                        <p>Loading...</p>
                    </AccordionDetails>
                      )}
                </Accordion>
            </>
        )
    }else{
    return(
        <>
        <Paper className={classes.SidebarPaper}>
            <Typography component="h2" color="inherit" variant="h6" gutterBottom>Refine your Search</Typography>
                {!props.isLoading ? ( 
                <>{filterForm}</>
                ) : (
                <>
                    <CircularProgress color="secondary" />
                    <p>Loading...</p>
                </>
              )}
        </Paper>
        </>
    )
    }
}

export default SidebarFilter;