import React, { Component } from 'react'

import NewVehiclesSEO from '../../components/SEO/NewVehicles'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import SidebarFilter from '../../components/InventoryListing/SidebarFilter/SidebarFilter'
import SortingBar from '../../components/InventoryListing/SortingBar/SortingBar'
import ResetFilterBar from '../../components/InventoryListing/ResetFilterbar/ResetFilterBar'
import ListingItem from '../../components/InventoryListing/ListingItem/ListingItem'
import Footer from '../../components/Footer/Footer'
import * as api from '../../api/vehicleApi'
import {NO_OF_ROWS} from '../../utils'

import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

//import TablePagination from '@material-ui/core/TablePagination'
//import TablePaginationActions from '../../components/InventoryListing/TablePaginationActions/TablePaginationActions'
import Pagination from '@material-ui/lab/Pagination'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'
import vehicleListStyle from '../../assets/jss/components/vehicleListStyle'
const useStyles = vehicleListStyle

const priceData =[
    {'id':1,'label':'1 to 5 Lakh','value':'1-5lakh'},
    {'id':2,'label':'5 to 10 Lakh','value':'5-10lakh'},
    {'id':3,'label':'10 to 15 Lakh','value':'10-15lakh'},
    {'id':4,'label':'15 to 20 Lakh','value':'15-20lakh'},
    {'id':5,'label':'20 to 25 Lakh','value':'20-25lakh'},
    {'id':6,'label':'25 to 30 Lakh','value':'25-30lakh'},
    {'id':7,'label':'30 to 35 Lakh','value':'30-35lakh'},
    {'id':8,'label':'35 to 50 Lakh','value':'35-50lakh'},
    {'id':9,'label':'50 to 1 Crore','value':'50lakh-1crore'},
    {'id':10,'label':'Above 1 Crore','value':'above-1crore'},
];

class NewVehicleListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            vehicles:[],
            keyword:'',
            makes:[],
            models:[],
            transmissions:[],
            bodies:[],
            colors:[],
            years:[],
            priceRanges:[],
            sorted_column: 'id',
            order: 'desc',
            search_params:{year:'',color:'',body:'',transmission:'',make:'',model:'',priceRange:''},
            total:0,
            pageNumber:0,
            viewType:"List", //Module
            badges:[]
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleViewType = this.handleViewType.bind(this);
        this.searchVehicle = this.searchVehicle.bind(this);
        this.setKeyword = this.setKeyword.bind(this);
        
    }
    componentDidMount() {
        this.setState({isLoading:true});
        this.setState({priceRanges:priceData});
        api.getNewFilterData().then((response)=>{
            this.setState({makes:response.data.make,isLoading:false});
            this.setState({models:response.data.model,isLoading:false});
            this.setState({transmissions:response.data.transmission,isLoading:false});
            this.setState({bodies:response.data.body,isLoading:false});
            this.setState({colors:response.data.color,isLoading:false});
            this.setState({years:response.data.year,isLoading:false});
        })
        
        api.getBadgesList().then((response)=>{
            this.setState({badges:response.data,isLoading:false});
        })
        if(this.props.location.search){
            let querySearch = this.props.location.search.replace('?',"");
            let result = querySearch.split("&");
            let paramsObj = {};
            for(let i=0;i<result.length;i++){
                let temp = result[i].split("=");
                paramsObj[temp[0]]=temp[1];
            }
            if(paramsObj['page']){
                this.setState({pageNumber:paramsObj['page']-1});
            }
            if(paramsObj['keyword']){
                this.setState({keyword:paramsObj['keyword']});
            }
            let queryObj ={year:'',color:'',body:'',transmission:'',make:'',model:'',priceRange:''};
            var searchParams = {...this.state.search_params}
            
            if(paramsObj['year']){
                searchParams.year = paramsObj['year'];
                queryObj.year = paramsObj['year'];
            }
            if(paramsObj['priceRange']){
                searchParams.priceRange = paramsObj['priceRange'];
                queryObj.priceRange = paramsObj['priceRange'];
            }
            if(paramsObj['make']){
                searchParams.make = paramsObj['make'];
                queryObj.make = paramsObj['make'];
            }
            if(paramsObj['model']){
                searchParams.model = paramsObj['model'];
                queryObj.model = paramsObj['model'];
            }

            if(paramsObj['transmission']){
                searchParams.transmission = paramsObj['transmission'];
                queryObj.transmission = paramsObj['transmission'];
            }

            if(paramsObj['body']){
                searchParams.body = paramsObj['body'];
                queryObj.body = paramsObj['body'];
            }


            if(paramsObj['color']){
                var col = paramsObj['color'];
                col=col.replace("[","");
                col=col.replace("]","");
                var color = col.split(",");
                searchParams.color = color;
                queryObj.color = color;
            }
                
           this.setState({ search_params: searchParams }); 
            api.getNewVehiclesList(1,this.state.sorted_column,this.state.order,JSON.stringify(queryObj),this.state.keyword).then((response)=> {
                this.setState({vehicles:response.data.result,total:response.data.total,isLoading:false});
            })
        }else{
            api.getNewVehiclesList(1,this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined,this.state.keyword).then((response)=> {
                this.setState({vehicles:response.data.result,total:response.data.total,isLoading:false});
            })
        }
        window.scrollTo(0, 0)
        
    }
    searchVehicle(keyword){
        if(keyword !== '' && keyword !== 'undefined'){
            this.setState({
                keyword:keyword
             },this.getVehicleListings)
        }else{
            this.setState({
                keyword:''
             },this.getVehicleListings)
        }
        
    }

    handleSidebarFilterSubmit(values) { 
        this.setState({ search_params: values },this.getVehicleListings); 
    }

    handleSortingBarSubmit(values) {
        let column = 'id';
        let order = 'desc';
        if(values === 'PA'){
            column = 'price';
            order = 'asc';
        }else if(values === 'PD'){
            column = 'price';
            order = 'desc';
        }else if(values === 'YA'){
            column = 'year';
            order = 'asc';
        }else if(values === 'YD'){
            column = 'year';
            order = 'desc';
        }else if(values === 'DD'){
            column = 'id';
            order = 'desc';
        }else{
            column = 'id';
            order = 'desc';
        }
        this.setState({ sorted_column: column,order: order },this.getVehicleListings);
    }

    handleViewType(type) {
        this.setState({viewType:type});

    }

    getVehicleListings(){
        let queryString = '/new-vehicles';
        var searchParamsObj = this.state.search_params;
        
        let i=0;
        if(this.state.keyword){
            queryString = queryString + `?keyword=${this.state.keyword}`;
            i++;
        }
        for (const [key, value] of Object.entries(searchParamsObj)) {
            if(value){
                var delimiter = (i===0)?'?':'&';
                if((Array.isArray(value))){
                    if(value.length>0){
                        queryString = queryString + delimiter + `${key}=[${value}]`;
                    }else{
                        queryString = queryString + '';
                    }
                }else{
                        queryString = queryString + delimiter + `${key}=${value}`;
                }
                i++;
            }
        }
        
        
        this.props.history.push(queryString);

        api.getNewVehiclesList(1,this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined,this.state.keyword).then((response)=> {
            this.setState({vehicles:response.data.result,total:response.data.total,isLoading:false});
        })
    }


    deleteFilter(filter,name){
        const { search_params } = { ...this.state };
        const currentSearch = search_params;
        if(filter === "keyword"){
            this.setState({keyword:''},this.getVehicleListings);
        }else if(filter === "color"){
            let c = currentSearch["color"].filter(function(item){ return item !== name; });
            currentSearch['color'] = c ? c : null;
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter==="reset"){
            currentSearch['year'] = "";
            currentSearch['make'] = "";
            currentSearch['model'] = "";
            currentSearch['transmission'] = "";
            currentSearch['body'] = "";
            currentSearch['color']="";
            currentSearch['priceRange']="";
            this.setState({ search_params: currentSearch,keyword:'' } ,this.getVehicleListings);
        }else{
            currentSearch[filter] = "";
            this.setState({ search_params: currentSearch } ,this.getVehicleListings);
        }
    }

        //debugger;
        /*
        if(filter === "price"){
            currentSearch['price'] = "";
            this.setState({ search_params: currentSearch } ,this.getVehicleListings);
        }else if(filter === "year"){
            currentSearch['year'] = "";
            this.setState({ search_params: currentSearch } ,this.getVehicleListings);
        }else if(filter === "make"){
            currentSearch['make'] = "";
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter === "model"){
            currentSearch['model'] = "";
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter === "transmission"){
            currentSearch['transmission'] = "";
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter === "body"){
            currentSearch['body'] = "";
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter === "color"){
            let c = currentSearch["color"].filter(function(item)
                {
                    return item !== name;
                });

            currentSearch['color'] = c ? c : null;
            this.setState({ search_params: currentSearch} ,this.getVehicleListings);
        }else if(filter === "keyword"){
            this.setState({keyword:''},this.getVehicleListings);
        }else{
            currentSearch['year'] = "";
            currentSearch['make'] = "";
            currentSearch['model'] = "";
            currentSearch['transmission'] = "";
            currentSearch['body'] = "";
            currentSearch['color']="";
            this.setState({ search_params: currentSearch,keyword:'' } ,this.getVehicleListings);
        }
        
               

    }
    */
    handleChangePage(event,page){
        // if(this.state.pageNumber===page){
        //     return false;
        // }
        this.setState({isLoading:true});
        api.getNewVehiclesList((page),this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined,this.state.keyword).then((response)=> { 
            this.setState({vehicles:response.data.result,total:response.data.total,pageNumber:page,isLoading:false});
        })
        
    }

    setKeyword(keyword){
        this.setState({keyword:keyword});
    }
    
    render() {
        const { classes } = this.props;
        let gridParentClass = '';
        if(this.state.viewType !== "List"){
            gridParentClass = 'wr-grid-listing';
        }
        return(
            <ThemeProvider theme={whrepoTheme}>
                <NewVehiclesSEO />
                 <Header user={this.props.user} isAuthenticated={this.props.isAuthenticated}/>
                 <main className={classes.main}>
                <Container>
                    <Breadcrumbs page="new"/>
                    <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                        New Vehicles
                    </Typography>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <SidebarFilter 
                            makes={this.state.makes} 
                            models={this.state.models}
                            transmissions={this.state.transmissions} 
                            bodies={this.state.bodies}
                            colors={this.state.colors}
                            years={this.state.years}
                            priceRanges={this.state.priceRanges}
                            handleFilter={this.handleSidebarFilterSubmit.bind(this)}
                            search_params={this.state.search_params}
                            keyword={this.state.keyword}
                            handleSearch={this.searchVehicle}
                            setKeyword={this.setKeyword}
                            isLoading={this.state.isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                        
                            {
                             this.state.search_params.year ||
                             this.state.search_params.body || 
                             this.state.search_params.make ||
                             this.state.search_params.model || 
                             this.state.search_params.transmission ||
                             this.state.search_params.color || 
                             this.state.search_params.priceRange || 
                             this.state.keyword?(<ResetFilterBar search_params={this.state.search_params} keyword={this.state.keyword} priceRanges={this.state.priceRanges} deleteFilter={this.deleteFilter.bind(this)}/>):""
                            }
                            <Paper className={classes.paper} spacing={3}>
                                {!this.state.isLoading ? ( 
                                <Typography component="h4" color="inherit" variant="h5">
                                {this.state.total} {(this.state.total>1)?"Vehicles":"Vehicle"}
                                </Typography>
                                ) : (
                                    <>
                                        <CircularProgress color="secondary" size="2rem"/>
                                    </>
                                  )}
                            </Paper>
                            {!this.state.isLoading ? (
                            <>
                            <SortingBar handleSortBy={this.handleSortingBarSubmit.bind(this)} handleViewType={this.handleViewType}/>
                            
                            <div className={classes.listContainer}>
                            <div className={`wr-listing ${gridParentClass}`}>
                                {
                                    this.state.vehicles.map((vehicle) => <ListingItem key={vehicle.id}
                                                                            vehicle={vehicle}
                                                                            viewType={this.state.viewType}
                                                                            badges={this.state.badges} 
                                                                            vType = "New"
                                                                            />)
                                }
                                
                            </div>
                            </div>
                            </>
                            ) : (
                                <Paper className={classes.paper} spacing={2}>
                                    <CircularProgress color="secondary" size="2rem"/>
                                </Paper>
                              )}
                            {/* {this.state.total > NO_OF_ROWS ?
                                <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={NO_OF_ROWS}
                                page={this.state.pageNumber}
                                onChangePage={this.handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                //onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> : null
                            } */}
                            {this.state.total > NO_OF_ROWS ?
                                <Pagination 
                                className={classes.pagination}
                                count={Math.ceil(this.state.total / NO_OF_ROWS)}
                                page={this.state.pageNumber===0?1:this.state.pageNumber} 
                                onChange={this.handleChangePage} 
                                color='secondary'
                                siblingCount={0}
                                boundaryCount={2}
                                showFirstButton
                                showLastButton
                                />
                            :null}
                        </Grid>
                    </Grid>
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(NewVehicleListing)


