import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      //backgroundColor: '#f5f5f5',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));

  export default function ResetFilterBar(props) {
    
    let params = props.search_params;
   
    const classes = useStyles();
    // const handleConditionDelete = () => {
    //   props.deleteFilter("condition");
    // };
  
    const handlePriceDelete = () => {
      props.deleteFilter("priceRange");
    }; 
    const handleYearDelete = () => {
      props.deleteFilter("year");
    };
    const handleMakeDelete = () => {
      props.deleteFilter("make");
    };
    const handleModelDelete = () => {
      props.deleteFilter("model");
    };
    const handleTransmissionDelete = () => {
      props.deleteFilter("transmission");
    };
   const handleBodyDelete = () => {
      props.deleteFilter("body");
    }; 
    const handleResetDelete = () => {
      props.deleteFilter("reset");
    };

    const handleColorDelete = (name) => {
      props.deleteFilter("color",name);
    }; 
    const handleKeywordDelete = () => {
      props.deleteFilter("keyword");
    }; 

  
    return (
        <>
        <Paper className="wr-reset-filter-listing">
        <div className={classes.root}>
        {params.priceRange?(<Chip label={`Price: ${props.priceRanges.find(price => price.value === params.priceRange).label}`} onDelete={handlePriceDelete} color="secondary" variant="outlined" />):null} 

            {props.keyword?(<Chip label={`Keyword: ${props.keyword}`} onDelete={handleKeywordDelete} color="secondary" variant="outlined" />):null} 
            {params.year?(<Chip label={`Year: ${params.year}`} onDelete={handleYearDelete} color="secondary" variant="outlined" />):null}
            {params.make?(<Chip label={`Make: ${params.make}`} onDelete={handleMakeDelete} color="secondary" variant="outlined" />):null}
            {params.model?(<Chip label={`Model: ${params.model}`} onDelete={handleModelDelete} color="secondary" variant="outlined" />):null}
            {params.transmission?(<Chip label={`Transmission: ${params.transmission}`} onDelete={handleTransmissionDelete} color="secondary" variant="outlined" />):null}
            {params.body?(<Chip label={`Body: ${params.body}`} onDelete={handleBodyDelete} color="secondary" variant="outlined" />):null}
            {params.color ? params.color.map((param)=>{
              return param? (<Chip key={param} label={`Color: ${param}`} onDelete={handleColorDelete.bind(this,param)} color="secondary" variant="outlined" />):null
            }):null}
            {props.keyword || params.year || params.make || params.model || params.transmission || params.body || (params.color.length > 0) || params.priceRange ?(<Chip label="Reset All Filters" onDelete={handleResetDelete} color="default" onClick={handleResetDelete}/>):null }
            
        </div>
        </Paper>
        </>
    );
  }
  