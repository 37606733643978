import React from 'react';

import useForm from "react-hook-form";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '24px',
        borderRadius: '0 0 2px 2px',
        marginBottom: '1rem'
      },
      button: {
          margin: '5px',
          marginLeft: 0
      },
      buttonTop:{
          margin:0,
          flex: 1,
      },
      select:{
          display: 'flex',
      },
      profileImage:{
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          display: 'flex',
          overflow: 'hidden',
          border: '1px solid #e5e5e5',
          '& img':{
              width:'150px',
              height: '150px',
              objectFit: 'contain',
          }
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },

}));


  export default function Profile(props){
      //debugger;
    const user = props.user;
    const UpdateProfileSubmit = props.UpdateProfileSubmit
    //const [error, setError] = React.useState("");
    const classes = useStyles();
    const [userProfile, setUserProfile] = React.useState({firstName:user.first_name,lastName:user.last_name,email:user.email,phone:user.phone}); 
    const methods = useForm();
    const handleChange = (event) => {
        const { name, value } = event.target; 
       
        setUserProfile(oldValues => ({
            ...oldValues,
            [name]: value,
            }));
    }
    React.useEffect(() => {        
        setUserProfile({firstName:user.first_name,lastName:user.last_name,email:user.email,phone:user.phone});
    },[user.first_name,user.last_name,user.email,user.phone]);
    //const { handleSubmit, register, errors,formState: { isDirty, isSubmitting, touched, submitCount } } = methods;
    const { handleSubmit, register, errors } = methods;
        return (
                 
                <form className={classes.form} noValidate onSubmit={handleSubmit(UpdateProfileSubmit)}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      name="firstName"
                      autoComplete="fname"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange.bind(this)}
                      
                      value={userProfile.firstName}
                      error={!!errors.firstName}
                      inputRef={register({
                        required: 'Required',
                        maxLength: {value:25,message:'Chars exceeded!'}
                      })}
                      InputLabelProps={{shrink:true}}
                    />
                    {errors.firstName && errors.firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.firstName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6} >
                    <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      name="lastName"
                      autoComplete="lname"
                      //margin="normal"
                      variant="outlined"
                      value={userProfile.lastName}
                      onChange={handleChange.bind(this)}
                      fullWidth
                      error={!!errors.lastName}
                      inputRef={register({
                        required: 'Required',
                        maxLength: {value:30,message:'Chars exceeded!'}
                      })}
                      InputLabelProps={{shrink:true}}

                    />
                    {errors.lastName && errors.lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.lastName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      variant="outlined"
                      disabled={true}
                      value={userProfile.email}
                      fullWidth
                      error={!!errors.email}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:'Invalid Email Address.'},
                                  maxLength: {value:191,message:'Chars exceeded!'}
                      })}
                      InputLabelProps={{shrink:true}}
                    />
                    {errors.email && errors.email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      autoComplete="phone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      value={userProfile.phone}
                      onChange={handleChange.bind(this)}
                      inputRef={register({
                        required: 'Required',
                        //pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
                                  message:'Invalid phone.'},
                                  maxLength: {value:10,message:'Chars exceeded!'}
                        //maxLength: {value:10,message:'Chars exceeded!'}
                      })}
                      InputLabelProps={{shrink:true}}
                    />
                    {errors.phone && (errors.phone.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.phone.message}</Typography>:''}
                    {/* {errors.email && (errors.email.message)?errors.email.message:''} */}
                    </Grid>
                    
                    </Grid>
                        
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<SaveIcon />}
                                    //disabled={( touched.length() < 0| isSubmitting)?true:false}
                                    type="submit"
                                    title="Update Profile"
                                >
                                    Update Profile
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                                
            
        )
    // }
    // );
}
