import React from 'react'
import { Helmet } from 'react-helmet'

export default function ProfileSEO() {
  return (
    <>
        <Helmet>
            <title>Profile &#8226; Wheels Repository  </title>
            <meta name="description" content="Profile - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/profile/" />
        </Helmet>
    </>
  )
}

