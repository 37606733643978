import React from 'react'
import { Helmet } from 'react-helmet'

const DealerDetailSEO = (props) => {
    const { dealer } = props;
    const dealerName = dealer.name?dealer.name.toLowerCase().replace(" ","-"):"";
  return (
    <>
        <Helmet>
            <title>{`${dealer.name}`} &#8226; Wheels Repository  </title>
            <meta name="description" content={`Browse all vehicles listed by ${dealer.name}`} />
            <link rel="canonical" href={`https://whrepo.com/dealer/${dealerName}/${dealer.id}/${dealer.loc_id}/`} />
        </Helmet>
    </>
  )
}
export default DealerDetailSEO;
