//import axios from 'axios';
import axios from './axiosInstance'
//const base_url = `http://localhost:8000` //http://127.0.0.1:8000/api/vehiclesList

//GET VEHICLES LIST API
export function getVehiclesList(pageNumber, sorted_column, order, params) {

    return axios.get(`/api/vehiclesList?page=${pageNumber}&column=${sorted_column}&order=${order}&params=${params}`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getUsedVehiclesList(pageNumber, sorted_column, order, params) {

    return axios.get(`/api/usedVehiclesList?page=${pageNumber}&column=${sorted_column}&order=${order}&params=${params}`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getNewVehiclesList(pageNumber, sorted_column, order, params,keyword) {

    return axios.get(`/api/newVehiclesList?page=${pageNumber}&column=${sorted_column}&order=${order}&params=${params}&keyword=${keyword}`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getRecentVehicles(count='all',condition='all') {
    return axios.get(`/api/vehicles/recent?count=${count}&condition=${condition}`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getSimilarVehicles(count='all',condition='all',body='all',vehicle="all") {
    return axios.get(`/api/vehicles/similar?count=${count}&condition=${condition}&body=${body}&vehicle=${vehicle}`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function fetchVehicles(condition,orderby,order,limit) {
    return axios.get('/api/fetchVehicles', {
        params: {
          condition,
          orderby,
          order,
          limit,
        }
    })
    //.then(function (response) { console.log(response); })
    .catch(error=> {
    console.log(error.response);
    return{
        errors: error.response
    }
    });
    
}

export function getVehicleDetail(id,type) {

    // return axios.get(`/api/vehicleDetail/${id}`)
    return axios.get('/api/vehicleDetail',{
        params: {
            id,
            type
          }  
    })

    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getFilterData(){
    return axios.get(`/api/vehiclesList/getFilterData`)
    .catch(error=> {
        console.log(error.response)
        return {
            errors: error.response
        }
    })
}

export function getNewFilterData(){
    return axios.get(`/api/vehiclesList/getNewFilterData`)
    .catch(error=> {
        console.log(error.response)
        return {
            errors: error.response
        }
    })
}

export function getUsedFilterData(){
    return axios.get(`/api/vehiclesList/getUsedFilterData`)
    .catch(error=> {
        console.log(error.response)
        return {
            errors: error.response
        }
    })
}
//https://stackoverflow.com/questions/48980380/returning-data-from-axios-api
//https://github.com/axios/axios
// export function getMakesList(){
//     // const promise = axios.get(`/api/makesList`)
//     // const dataPromise = promise.then((response) => response.data)
//     // return dataPromise
//     return axios.get(`/api/makesList`)
//     .catch(error=> {
//         debugger;
//         console.log(error.response)
//         return {
//             errors: error.response
//         }
//     })
// }

export function getOptionList(){
    return axios.get(`/api/optionList`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getOptionCategoryList(){
    return axios.get(`/api/optionCategoryList`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function getBadgesList(){
    return axios.get(`/api/badges/getAll`)
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}

export function requestMoreInfo(data){
    return axios.post('/api/customer/getMoreInfo',data)
}

export function bookTestDrive(data){
    return axios.post('/api/customer/bookTestDrive',data)
}

export function referToFriend(data){
    return axios.post('/api/customer/referToFriend',data)
}



