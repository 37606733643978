import React from 'react';
import { Link as RouterLink, Route} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: '20px'
  },
  capitalize:{
    textTransform: "capitalize",
  },
  "@media only screen and (max-width: 599px)":{
    root: {
      "& .MuiTypography-body1":{
        fontSize: "0.75rem",
      },
    },
  },
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;
/*
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const splitTitle = (s) => {
  var strArray = s.split("-");
  strArray = strArray.slice(0,-1);
  return strArray.join(' ');
}

function capital_letter(str) 
{
    str = str.split("-");

    for (var i = 0, x = str.length; i < x-2; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
}
*/
function renameBreadcrumbs(value){
  if(value.indexOf('_') > 0){
    var v = value.split("_");
    return v[0];
  }else{
    return value;
  }
  //return value;

}

export default function SimpleBreadcrumbs(props) {
  const classes = useStyles();
  const vehicle = props.vehicle; 
  const page = props.page;
  if(page==="404"){
    return (
      <div className={classes.root}>
        <Paper elevation={1} className={classes.paper}>
          <Route>
            <Breadcrumbs aria-label="Breadcrumb">
              <LinkRouter color="inherit" to="/" >Home</LinkRouter>
              <Typography color="textPrimary">Page Not Found</Typography>
            </Breadcrumbs>
          </Route>
        </Paper>
      </div>
    )
  }else if(page==="new"){
    return (
      <div className={classes.root}>
        <Paper elevation={1} className={classes.paper}>
          <Route>
            <Breadcrumbs aria-label="Breadcrumb">
              <LinkRouter color="inherit" to="/" >Home</LinkRouter>
              <Typography color="textPrimary">New Vehicles</Typography>
            </Breadcrumbs>
          </Route>
        </Paper>
      </div>
    )
  }else if(page==="used"){
    return (
      <div className={classes.root}>
        <Paper elevation={1} className={classes.paper}>
          <Route>
            <Breadcrumbs aria-label="Breadcrumb">
              <LinkRouter color="inherit" to="/" >Home</LinkRouter>
              <Typography color="textPrimary">Used Vehicles</Typography>
            </Breadcrumbs>
          </Route>
        </Paper>
      </div>
    )
  }else{
    return (
      <div className={classes.root}>
        <Paper elevation={1} className={classes.paper}>
          <Route>
            
          {({location }) => {
            let _location = location.pathname;
            _location = location.pathname.replace(/\bdealer\b/, "dealers").replace(/vehicle\b/g, "vehicles");
            if(_location.indexOf('&') > 0){
              _location = _location.slice(0,_location.indexOf('&'));
            }
                 
            let pathnames = _location.split('/').filter(x => x);
            pathnames = pathnames.slice(0,2);
  
            return (
                  <Breadcrumbs aria-label="Breadcrumb">
                        <LinkRouter color="inherit" to="/" >Home</LinkRouter>
                        
                        {pathnames.map((value, index) => {
                              const last = (index === pathnames.length - 1);
                              const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                              const breadcrumbText = value.replace(/-/g,' ');
                              
                              return last ? (
                                  <Typography color="textPrimary" key={to} className={classes.capitalize}>{(vehicle)?vehicle.title:renameBreadcrumbs(breadcrumbText)}</Typography>
                              ) : (
                                  <LinkRouter color="inherit" to={to} key={to} className={classes.capitalize}>{breadcrumbText}</LinkRouter>
                              );
                        })}
                    </Breadcrumbs>
                    );
          }}
          </Route>
        </Paper>
      </div>
    );
  }
}
