import React from 'react';

import useForm from "react-hook-form";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';



const TestDrive = (props) => {
    const { register, handleSubmit, errors } = useForm({
      defaultValues: {firstName:props.user.first_name,
        lastName:props.user.last_name,
        email:props.user.email,
        phone:props.user.phone
      },
    });
    //const onSubmit = props.handleSubmitTestDrive;
    const isLoading = props.isLoading;
    const vehicle_id = props.vehicle_id;

    const onSubmit = data => {
      data.vehicle_id = vehicle_id;
      props.handleSubmitTestDrive(data);
}; 
    
    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">Book a Test Drive</DialogTitle>
                                        
        <DialogContent>
        <DialogContentText>
            To book a test drive, please enter your details here. We will update you shortly.
        </DialogContentText>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      name="firstName"
                      autoComplete="fname"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      error={!!errors.firstName}
                      inputRef={register({
                        required: 'Required',
                        maxLength: {value:25,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.firstName && errors.firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.firstName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      name="lastName"
                      autoComplete="lname"
                      //margin="normal"
                      variant="outlined"
                      fullWidth
                      error={!!errors.lastName}
                      inputRef={register({
                        required: 'Required',
                        maxLength: {value:30,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.lastName && errors.lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.lastName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:'Invalid Email Address.'},
                                  maxLength: {value:191,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.email && errors.email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      autoComplete="phone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      inputRef={register({
                        required: 'Required',
                        //pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                          message:'Invalid phone.'},
                          maxLength: {value:10,message:'Chars exceeded!'}
                        //maxLength: {value:10,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.phone && (errors.phone.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.phone.message}</Typography>:''}
                    {/* {errors.email && (errors.email.message)?errors.email.message:''} */}
                    </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    defaultValue="2017-05-24"
                    helperText="format: mm/dd/yyyy"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    fullWidth
                    inputRef={register({})}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="time"
                    name="time"
                    label="Time"
                    type="time"
                    defaultValue="10:30"
                    helperText="format: 11:11 AM"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    inputProps={{
                    step: 300, // 5 min
                    }}
                    fullWidth
                    inputRef={register({})}
                />
            </Grid>
            
        </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleDialogClose} color="default" variant="outlined">Cancel</Button>
            <Button type="submit" color="primary" variant="outlined">Submit</Button>
            {isLoading?<CircularProgress color="secondary" size={30}/>:null}

        </DialogActions>
        </form>
        </>
    );
};

export default TestDrive;