import React from 'react'
import { NavLink } from "react-router-dom"

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
 accountMenu:{
  "& .MuiMenu-paper":{
    top: "65px !important"
  }
}
  
}))

export default function AccountMenu(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

    return (
    
      <>
        <Button aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
          <AccountCircleIcon />
        </Button>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.accountMenu}
        >
          <MenuItem onClick={handleClose}>{props.user.first_name} {props.user.last_name}</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}><NavLink to="/profile/" >Profile</NavLink></MenuItem>
          <MenuItem onClick={handleClose}><NavLink to="/logout/" >Logout</NavLink></MenuItem>
        </Menu>
      </>
      
    )
  
}