import React from 'react'
import { NavLink,useLocation} from "react-router-dom"

import { makeStyles, useTheme} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from "@material-ui/icons/Search"

import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
    accordion:{
        color: theme.palette.text.secondary,
    },
    SidebarPaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        overflow: "hidden"
    },
    form:{
        width: "100%",
    },
    formControl: {
        marginBottom: theme.spacing(3),
        width:"100%"
    },
    formLabel:{
        fontSize: "1em",
        fontWeight: "600"
    },
    dealerFilter: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
      dealerFilterSmall:{
          padding:"0 16px",
      }
  }))
const DealerFilter = (props) => {
    const classes = useStyles()

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const [expanded, setExpanded] = React.useState('')

    const handlePanelChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const handleKeywordChange = (event) => {
        props.setKeyword(event.target.value)
    }

    const cities = props.cities
    const kword = props.keyword
    const handleDelete = props.handleDelete
    const handleDeleteKeyword = props.handleDeleteKeyword
    const handleDeleteCity = props.handleDeleteCity
    const location = useLocation()
    let params = new URLSearchParams(location.search)

    const filterForm = <form className={classes.form}>
    <FormControl component="fieldset" className={classes.formControl}>
        <TextField 
            id="keyword"
            type="search" 
            label="Keyword" 
            value={kword?kword:''} 
            helperText="Enter Keyword to Search"
            className={classes.textField}
            onChange={handleKeywordChange}
            InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton 
                        aria-label="search dealer"
                        onClick={props.handleSearch}
                        //onMouseDown={handleMouseDownSearch}
                        size="small"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            InputLabelProps={{
                shrink: kword?true:false,
              }}
        />
     </FormControl>
     <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend" className={classes.formLabel}>Browse Dealers by City</FormLabel>   
        <List dense={true}>
        {cities.map((city)=>{ return(
          <ListItem key={city.city} component={NavLink} to={`/dealers?city=${city.city}`}  disableGutters>
            <ListItemText
              primary={<><span>{city.city}</span> <Chip variant="default" color="secondary" size="small" label={city.cnt} /></>}
            />
          </ListItem>)

        })}
        
    </List>
    </FormControl>
    </form>
    

    const renderFilter = () =>{
        
        if(params.get('keyword')){
            if(params.get('city')){
                return (<><Chip 
                    size="small"
                    label={"keyword:"+params.get('keyword')} 
                    onClick={handleDeleteKeyword} 
                    color="secondary" 
                    variant="outlined" 
                    onDelete={handleDeleteKeyword}
                /><Chip 
                size="small"
                label={"City:"+params.get('city')} 
                onClick={handleDeleteCity} 
                color="secondary" 
                variant="outlined" 
                onDelete={handleDeleteCity}
                /><Chip 
                size="small"
                label="Reset All Filters" 
                onClick={handleDelete} 
                color="default"
                onDelete={handleDelete}
            /></>)
            }else{
                return (<><Chip 
                    size="small"
                    label={"keyword:"+params.get('keyword')} 
                    onClick={handleDeleteKeyword} 
                    color="secondary" 
                    variant="outlined" 
                    onDelete={handleDeleteKeyword}
                /><Chip 
                size="small"
                label="Reset All Filters" 
                onClick={handleDelete} 
                color="default"
                onDelete={handleDelete}
            /></>)
            }
        }else{
            if(params.get('city')){
               return(<><Chip 
                size="small"
                label={"City:"+params.get('city')} 
                onClick={handleDeleteCity} 
                color="secondary" 
                variant="outlined" 
                onDelete={handleDeleteCity}
                /><Chip 
                size="small"
                label="Reset All Filters" 
                onClick={handleDelete} 
                color="default"
                onDelete={handleDelete}
            /></>)
            }else{
                return null
            }
        }
    }
    if(smallScreen){
        return(
            <>
                <Accordion expanded={expanded} className={classes.accordion} onChange={handlePanelChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography component="h2" color="inherit" variant="h6" gutterBottom>Find a Dealer</Typography>
                    
                    </AccordionSummary>
                    <div className={`${classes.dealerFilter} ${classes.dealerFilterSmall}`}>
                        {renderFilter()}
                    </div>
                    <AccordionDetails>
                    
                    {filterForm}
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }else{
    return(
        <Paper className={classes.SidebarPaper}>
            <Typography component="h2" color="inherit" variant="h6" gutterBottom>Find a Dealer</Typography>
            <div className={classes.dealerFilter}>
            {renderFilter()}
            </div>
            {filterForm}
        </Paper>
    )
    }
}

export default DealerFilter