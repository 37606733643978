import React from 'react'
import { Helmet } from 'react-helmet'

export default function HomeSEO() {
  return (
        <Helmet>
            <title>Wheels Repository: Buy &amp; Sell New &amp; Used Vehicles in Nepal</title>
            <meta name="description" content="Browse new and used cars, find local dealers or sellers in Nepal, search by vehicle models, specifications, and features, narrow results, get the best pricing and more." />
            <link rel="canonical" href="https://whrepo.com" />
        </Helmet>
  )
}