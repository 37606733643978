import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {BASE_URL} from '../../../utils';

const useStyles = makeStyles(theme => ({
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar:{
    backgroundColor:"transparent",
  },
  "@media only screen and (max-width: 599px)":{
    tabRoot: {
      "& .MuiTab-root":{
        fontSize:"0.75rem",
      }
    },
  }
  
}));

//export default function ListingTabs(props) {
const ListingTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renderOptions = () => {
    let optId = [];
    
    if(props.vehicle.attribute && (props.vehicle.attribute.option_ids !== null)) {
      optId = props.vehicle.attribute.option_ids.split(',');
    }
    if(props.optionCategories.length > 0){
      return (
      <Box p={3}>
      <Grid container spacing={3}>
        {
          props.optionCategories.map((optionCategory)=>{
            let hasChild = false;
          //if(props.options.length>0){
            props.options.forEach((option) => {
              if((option.oc_id === optionCategory.id) && (optId.indexOf(option.id.toString()) !== -1 )){
                hasChild = true;
              }
            });
            //if(hasChild===true){
              return (
                <>
                {(props.options.length>0)?
                <>
                {(hasChild)?
                <Grid item xs={12} md={4} key={`${optionCategory.id}`}>
                  {hasChild?<Typography component="h3" variant="subtitle2">{`${optionCategory.optioncategory_name}`}</Typography>:null}
                  <List dense={true}>
                    {
                      props.options.map((option) => {
                        //if((option.oc_id === optionCategory.id) && (optId.indexOf(option.id.toString()) !== -1 )){
                          return(
                            <>
                            {((option.oc_id === optionCategory.id) && (optId.indexOf(option.id.toString()) !== -1 ))?
                            <ListItem key={`${option.id}`} disableGutters>
                              <ListItemText primary={`${option.option_name}`} />
                            </ListItem>
                            :null}
                            </>
                          )
                        //}
                      })
                    }
                  </List>
                </Grid>
                :null}
                </>
                :null}
                </>
                )
            //}
          //}
            
          })
        }
      </Grid>
      </Box> 
      );
    }
  }

  return (
    <div className={classes.tabRoot}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Features" {...a11yProps(1)} />
          <Tab label="Specification" {...a11yProps(2)} />
          <Tab label="Information" {...a11yProps(3)} />
          <Tab label="Finance" {...a11yProps(4)} />
          <Tab label="Warranty" {...a11yProps(5)} />
          
        </Tabs>
      </AppBar>
      
      <TabPanel value={value} index={0}>
      {props.vehicle.vehicle_description || props.vehicle.vehicle_description!== null? <div dangerouslySetInnerHTML={{ __html: props.vehicle.vehicle_description }} />:<p>Not added yet.</p>}        
      </TabPanel>
      
      <TabPanel value={value} index={1}>
      {/* {renderOptions} */}
        {(props.vehicle.attribute && props.vehicle.attribute.option_ids)?renderOptions:<p><span>Options not listed yet.</span></p>}
      </TabPanel>
      

      <TabPanel value={value} index={2}>
      {props.vehicle.tech_specification || props.vehicle.tech_specification!== null?<div dangerouslySetInnerHTML={{ __html: props.vehicle.tech_specification }} />:<p>Not added yet.</p>}
      </TabPanel>
      
      
      <TabPanel value={value} index={3}>
      {props.vehicle.ad_desc || props.vehicle.ad_desc!== null?<div dangerouslySetInnerHTML={{ __html: props.vehicle.ad_desc }} />:<p>Not added yet.</p>}
      {props.vehicle.pdf_brochure || props.vehicle.pdf_brochure!== null?
      <Button variant="contained" color="default" startIcon={<PictureAsPdfIcon />} component="a" href={BASE_URL+"/storage/whrepo/"+props.vehicle.pdf_brochure}>Brochure</Button>
      :null
      }
      </TabPanel>
      
      <TabPanel value={value} index={4}>
      {props.vehicle.financing && props.vehicle.financing_flag===1? 
      <div>
        <strong>Description</strong>
        <p>{props.vehicle.financing.description || props.vehicle.financing.description!==null?<div dangerouslySetInnerHTML={{ __html: props.vehicle.ad_desc }} />:<span>Not added yet.</span>}</p>
        <strong>Down Payment</strong>
        <p>{props.vehicle.financing.downpayment || props.vehicle.financing.downpayment!==null?<>{props.vehicle.financing.downpayment}</>:<span>Not added yet.</span>}</p>
        <strong>Number of Payments</strong>
        <p>{props.vehicle.financing.number_of_payment || props.vehicle.financing.number_of_payment!==null?<>{props.vehicle.financing.number_of_payment}</>:<span>Not added yet.</span>}</p>
        <strong>Odometer</strong>
        <p>{props.vehicle.financing.odometer || props.vehicle.financing.odometer!==null?<>{props.vehicle.financing.odometer}</>:<span>Not added yet.</span>}</p>
        <strong>Payment</strong>
        <p>{props.vehicle.financing.payment || props.vehicle.financing.payment!==null?<>{props.vehicle.financing.payment}</>:<span>Not added yet.</span>}</p>
        <strong>Payment Type</strong>
        <p>{props.vehicle.financing.payment_type || props.vehicle.financing.payment_type!==null?<>{props.vehicle.financing.payment_type}</>:<span>Not added yet.</span>}</p>
        <strong>Source</strong>
        <p>{props.vehicle.financing.source || props.vehicle.financing.source!==null?<>{props.vehicle.financing.source}</>:<span>Not added yet.</span>}</p>
      </div>
      :"N/A"}
      </TabPanel>
      
      <TabPanel value={value} index={5}>
      {props.vehicle.warranty_flag===1? <div dangerouslySetInnerHTML={{ __html: props.vehicle.warranty_desc!==null?props.vehicle.warranty_desc:"Not added yet" }} />:"N/A"}
      </TabPanel>
    </div>
  );
}

export default ListingTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3} component="div" key={index}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  //children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
