import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import {BASE_URL} from '../../../utils';

class ListingGallery extends Component {
  render() {
    let images = [];
    if(this.props.images && this.props.images.length>0){
      this.props.images.forEach(image => {
        if(image.main_flag === "1" ){
          let tArray = image.path.split(".");
          images[0]={
            original: BASE_URL+"/storage/whrepo/"+image.path,
            thumbnail: BASE_URL+"/storage/whrepo/"+tArray[0]+"-300X300."+tArray[1],
          }
        }
      })
      this.props.images.forEach(image => {
        if(image.main_flag === "0" ){
          let tArray = image.path.split(".");
          images.push({
            original: BASE_URL+"/storage/whrepo/"+image.path,
            thumbnail: BASE_URL+"/storage/whrepo/"+tArray[0]+"-300X300."+tArray[1],
          })
          
        }
      })
    }
    return <ImageGallery items={images} />;
  }
}
export default ListingGallery;

//http://www.linxtion.com/demo/react-image-gallery/
//https://github.com/xiaolin/react-image-gallery
//https://www.npmjs.com/package/react-image-gallery