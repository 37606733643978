import React from 'react';

import useForm from "react-hook-form";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ReferToFriend = (props) => {
    const { register, handleSubmit, errors } = useForm({
      defaultValues: {firstName:props.user.first_name,
        lastName:props.user.last_name,
        email:props.user.email,
        phone:props.user.phone
      },
    });
    const onSubmit = props.handleSubmitReferToFriend;
    const isLoading = props.isLoading;

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">Email your Friend</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Please enter details here.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                id="firstName"
                label="First Name"
                type="text"
                name="firstName"
                variant="outlined"
                fullWidth
                autoFocus
                error={!!errors.firstName}
                inputRef={register({
                required: 'Required',
                maxLength: {value:25,message:'Chars exceeded!'}
                })}
                />
                {errors.firstName && errors.firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.firstName.message}</Typography>:''}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                id="lastName"
                label="Last Name"
                type="text"
                name="lastName"
                variant="outlined"
                fullWidth
                error={!!errors.lastName}
                inputRef={register({
                required: 'Required',
                maxLength: {value:30,message:'Chars exceeded!'}
                })}
                />
                {errors.lastName && errors.lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.lastName.message}</Typography>:''}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                id="email"
                label="Email Address"
                type="email"
                name="email"
                variant="outlined"
                fullWidth
                error={!!errors.email}
                inputRef={register({
                required: 'Required',
                pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message:'Invalid Email Address.'},
                maxLength: {value:191,message:'Chars exceeded!'}
                })}
                />
                {errors.email && errors.email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:''}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                id="phone"
                label="Phone Number"
                type="tel"
                name="phone"
                variant="outlined"
                fullWidth
                error={!!errors.phone}
                inputRef={register({
                required: 'Required',
                //pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                message:'Invalid phone.'},
                maxLength: {value:10,message:'Chars exceeded!'}
                //maxLength: {value:10,message:'Chars exceeded!'}
                })}
                />
                {errors.phone && (errors.phone.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.phone.message}</Typography>:''}
                {/* {errors.email && (errors.email.message)?errors.email.message:''} */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                id="friend_firstName"
                label="Friend's First Name"
                type="text"
                name="friend_firstName"
                variant="outlined"
                fullWidth
                autoFocus
                error={!!errors.friend_firstName}
                inputRef={register({
                required: 'Required',
                maxLength: {value:25,message:'Chars exceeded!'}
                })}
                />
                {errors.friend_firstName && errors.friend_firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.friend_firstName.message}</Typography>:''}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                id="friend_lastName"
                label="Friend's Last Name"
                type="text"
                name="friend_lastName"
                variant="outlined"
                fullWidth
                error={!!errors.friend_lastName}
                inputRef={register({
                required: 'Required',
                maxLength: {value:30,message:'Chars exceeded!'}
                })}
                />
                {errors.friend_lastName && errors.friend_lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.friend_lastName.message}</Typography>:''}
              </Grid>
              <Grid item xs={12}>
                <TextField
                //id="email"
                label="Friend's Email Address*"
                type="email"
                name="friend_email"
                variant="outlined"
                fullWidth
                error={!!errors.friend_email}
                inputRef={register({
                required: 'Required',
                pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message:'Invalid Email Address.'},
                maxLength: {value:191,message:'Chars exceeded!'}
                })}
                />
                {errors.friend_email && errors.friend_email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.friend_email.message}</Typography>:''}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                id="outlined-multiline-static"
                name="message"
                label="Message*"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                inputRef={register({})}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleDialogClose} color="default" variant="outlined">Cancel</Button>
            <Button type="submit" color="primary" variant="outlined">Submit</Button>
            {isLoading?<CircularProgress color="secondary" size={30}/>:null}
          </DialogActions>
          </form>
          </>
    );
};

export default ReferToFriend;