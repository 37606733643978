export const baseURL= () =>{
    const currentURL = window.location.href;
    if (currentURL.includes('localhost')) { 
        return 'http://localhost:8000';
    }
    if (currentURL.includes('192.168.1.66')) { 
        return 'http://192.168.1.66:8000';
    }
    if (currentURL.includes('192.168.1.71')) { 
        return 'http://192.168.1.71:8000';
    }
    if (currentURL.includes('beta.whrepo.com')) { 
        return 'http://accountbeta.whrepo.com';
    }
    if (currentURL.includes('whrepo.com')) { 
        return 'https://account.whrepo.com';
    }
}


export const BASE_URL = baseURL();
export const BACKEND_URL = BASE_URL;
export const CURRENCY = "NPR";
//export const BACKEND_URL = "http://127.0.0.1:8000/"
//export const BACKEND_URL = "http://account.whrepo.com/";

// export const BASE_URL = 'http://127.0.0.1:8000';
// export const IMAGE_DIR = 'http://127.0.0.1:8000/storage/';
//export const BASE_URL = 'http://192.168.1.66:8000';
//export const IMAGE_DIR = 'http://192.168.1.66:8000/storage/';
export const NO_OF_ROWS = 12 ;

export const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function getAbsolutePath() {
    var loc = window.location;
    var pathName = loc.pathname.substring(0, loc.pathname.lastIndexOf('/') + 1);//to get path of current url
    return loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));
}
