import React from 'react'
import { Helmet } from 'react-helmet'

export default function PrivateOwnerSignUpSEO() {
  return (
        <Helmet>
          <title>Sell Used Vehicle Online in Nepal &#8226; Wheels Repository</title>
          <meta name="description" content="Private owner sign up to post &amp; sell vehicle online in Nepal at whrepo.com" />
          <link rel="canonical" href="https://whrepo.com/sell-your-vehicle/" />
        </Helmet>
  )
}

