import React from 'react'
import { Helmet } from 'react-helmet'

export default function RegisterSEO() {
  return (
    <>
        <Helmet>
            <title>Register &#8226; Wheels Repository</title>
            <meta name="description" content="Register to browse and buy new and vehicles listed by local vehicle dealers or car showrooms and private owners from all over Nepal." />
            <link rel="canonical" href="https://whrepo.com/register/" />
        </Helmet>
    </>
  )
}

