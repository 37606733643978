import React, { Component } from 'react'

import DealerListingSEO from '../../components/SEO/DealerListing'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'
import DealerFilter from '../../components/DealerListing/DealerFilter/DealerFilter'
import DealerItem from '../../components/DealerListing/DealerItem/DealerItem'
import * as api from '../../api/dealerApi'
import {NO_OF_ROWS} from '../../utils'

import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'
//import Pagination from '@material-ui/lab/Pagination'

import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../../components/InventoryListing/TablePaginationActions/TablePaginationActions'

const useStyles = theme => ({
    root: {
        flexGrow: 1,
      },
    main: {
        marginTop: 100,
        minHeight: "calc(100vh - 341px)",
    },
    paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: '16px'
    },
    SidebarPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    overflow: "hidden"
    },
    formControl: {
        margin: theme.spacing(3),
    },
});

class DealerListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealers : [],
            cities: [],
            total : null,
            pageNumber:0,
            keyword:''
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.setKeyword = this.setKeyword.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteKeyword = this.handleDeleteKeyword.bind(this);
        this.handleDeleteCity = this.handleDeleteCity.bind(this);
    }
    componentDidMount() {
        
        api.getCities().then((response)=> {
            if(response.data.success){
                this.setState({cities:response.data.cities});
            }
        })

        let params = new URLSearchParams(this.props.location.search);

        if(params.get('keyword')){
            if(params.get('city')){
                api.getDealers(1,params.get('city'),params.get('keyword')).then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:params.get('keyword')});
                    }
                })
            }else{
                api.getDealers(1,null,params.get('keyword')).then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:params.get('keyword')});
                    }
                })
            }
            
        }else{
            if(params.get('city')){
                api.getDealers(1,params.get('city'),'').then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:''});
                    }
                })
            }else{
                api.getDealers(1,null,'').then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:''});
                    }
                })
            }
        }
        window.scrollTo(0, 0)
    }
    /* Change to ComponentDidUpdate */
    componentWillReceiveProps(nextProps) {
        let params = new URLSearchParams(nextProps.location.search)

        if(params.get('keyword')){
            if(params.get('city')){
                api.getDealers(1,params.get('city'),params.get('keyword')).then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:params.get('keyword')});
                    }
                 
                })
            }else{
                api.getDealers(1,null,params.get('keyword')).then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:params.get('keyword')});
                    }
                })
            }
        }else{
            if(params.get('city')){
                api.getDealers(1,params.get('city'),'').then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:''});
                    }
                })
            }else{
                api.getDealers(1,null,'').then((response)=> {
                    if(response.data.success){
                     this.setState({dealers:response.data.dealers.data,total:response.data.total,keyword:''});
                    }
                })
            }
        }
        
    }
    setKeyword(keyword){
        this.setState({'keyword':keyword});
    }
    handleChangePage(event,page){
        let params = new URLSearchParams(this.props.location.search);
        this.setState({pageNumber:page});
        const keyword = params.get('keyword')?params.get('keyword'):'';
        const city = params.get('city')?params.get('city'):null;
        debugger;
        api.getDealers(page+1,city,keyword).then((response)=> {
            if(response.data.success){
                this.setState({dealers:response.data.dealers.data,total:response.data.total});
               }
        })
    }
    handleSearch(e){
        const keyword = this.state.keyword
        let pathname = this.props.location.pathname;
        let params = new URLSearchParams(this.props.location.search)
        
        if(params.get('city')){
            if(keyword.length > 3){
                document.location = pathname + "?city="+params.get('city') + "&keyword="+keyword;
            }else{
                this.setKeyword(keyword);
            }
        }else{
            if(keyword.length > 3){
                document.location = pathname + "?keyword="+keyword;
            }else{
                this.setKeyword(keyword);
            }
        }
        
    }
    handleDelete = () => {
        this.setKeyword('');
        document.location = "/dealers"
    };
    handleDeleteKeyword = () => {
        let params =  new URLSearchParams(this.props.location.search)
        this.setKeyword('');
        if(params.get('city')){
            document.location = "/dealers?city="+params.get('city');
        }else{
            document.location = "/dealers"
        }
    };
    handleDeleteCity = () => {
        let params =  new URLSearchParams(this.props.location.search)
        if(params.get('keyword')){
            document.location = "/dealers?keyword="+params.get('keyword');
        }else{
            document.location = "/dealers"
        };
     }
    render(){
        const { classes } = this.props;
        

        return(
            <ThemeProvider theme={whrepoTheme}>
                <DealerListingSEO />
                 <Header user={this.props.user} isAuthenticated={this.props.isAuthenticated}/>
                <main className={classes.main}>
                <Container>
                    <Breadcrumbs />
                    <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                        Dealers
                    </Typography>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <DealerFilter setKeyword={this.setKeyword} 
                            keyword={this.state.keyword} 
                            cities={this.state.cities}
                            handleDelete={this.handleDelete} 
                            handleDeleteKeyword={this.handleDeleteKeyword}
                            handleDeleteCity={this.handleDeleteCity}
                            handleSearch={this.handleSearch}/>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.paper} spacing={3}>
                                <Typography component="h4" color="inherit" variant="h5">
                                    {this.state.total} {(this.state.total>1)?"Dealers":"Dealer"}
                                </Typography>
                            </Paper>
                            {this.state.dealers.length > 0 ?
                                    this.state.dealers.map((dealer, index) => <DealerItem key={index}
                                                                            dealer={dealer}
                                                                            
                                                                            />)
                                                                            : null
                            }
                            
                            {this.state.total > NO_OF_ROWS ?
                                <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={NO_OF_ROWS}
                                page={this.state.pageNumber}
                                onChangePage={this.handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                //onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> : null
                            }
                            {/* <Pagination count={5} color="secondary" /> */}
                        </Grid>
                    </Grid>
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )

    }
    
    
};

export default withStyles(useStyles)(DealerListing);