import React from 'react'

import ProfileSEO from '../../components/SEO/Profile'
import Header from '../../components/Header/Header'
import SimpleBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'
import Profile from '../../components/Profile/Profile'
import ChangePassword from '../../components/Profile/ChangePassword'
import * as api from '../../api/customerApi'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Alert from '@material-ui/lab/Alert'
import LinearProgress from '@material-ui/core/LinearProgress'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        "& .MuiDivider-root":{
          margin:"20px 0",
        }
      },
      main:{
        marginTop:100,
      },
      image: {
        backgroundImage: 'url(/images/flat-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      logo:{
        marginBottom: '55px',
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      cancelIcon:{
        float:"right",
      },
}));

export default function ProfilePage(props) {
  //console.log(props);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [profile, setProfile] = React.useState({});
  const classes = useStyles();
  const [profilePanel, setProfilePanel] = React.useState(true);
  const [changePasswordPanel, setChangePasswordPanel] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  React.useEffect(() => {        
    setProfile(props.user);
  },[props.user]);

  const handleCloseError=()=>{
      setError("");
  }
  const handleCloseSuccess=()=>{
    setSuccess("");
}

  const onUpdateProfileSubmit = (data) => {
    api.updateCustomer(props.user.id,data).then((response)=> {
      if(response.data.success){
        setSuccess('Profile Updated Successfully.');       
      }else{
        setError('Couldnot Update Profile.');      
        
      }
  })
  }
  const onChangePasswordSubmit = (data) => {
    //debugger;data
    data.id = props.user.id;
    if(data.new_password !== data.c_new_password){
      setError('Password mismatch');      
    }else{
      api.changePassword(data).then((response)=> {
        if(response.data.success){
          setSuccess('Password Changed Successfully.'); 
          api.logOut({'user':data.id}).then((response)=> {
          
            window.localStorage.removeItem("user");
            window.localStorage.removeItem("token");
            
            window.sessionStorage.removeItem("user");
            window.sessionStorage.removeItem("token");
            document.location='/';

          });
          //document.location='/';      
        }else{
          setError('Couldnot Change Password.');      
          
        }
      })
      
    }
    
  }
  const handleChangePanel = (panel) => {
    if(panel==='panel1'){
      setProfilePanel(!profilePanel);
      setChangePasswordPanel(changePasswordPanel);
         //this.setState({profilePanel: !this.state.profilePanel, changePasswordPanel: false});
    }else{
      setProfilePanel(profilePanel);
      setChangePasswordPanel(!changePasswordPanel);
         //this.setState({profilePanel: false, changePasswordPanel: !this.state.changePasswordPanel});    
    }
}
  return(<div>
    <ThemeProvider theme={whrepoTheme}>
      <ProfileSEO />
    <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
    <main className={classes.main}>
    <Container>
        <SimpleBreadcrumbs />
        
        <>
                    {props.fetching ? (<LinearProgress color="secondary" />) : (<div className={classes.loader}></div>)}
                    <Accordion expanded={profilePanel === true} onChange={handleChangePanel.bind(this,'panel1')}>
                        <AccordionSummary
                        expandIcon={profilePanel? <RemoveCircleIcon /> : <AddCircleIcon />}
                        aria-controls="edit-profile-content"
                        id="edit-profile-header"
                        >
                        <Typography>Edit Profile</Typography>
                        {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleCloseError}>{error}</Alert>:""}
                        {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleCloseSuccess}>{success}</Alert>:""}

                        </AccordionSummary>
                        <AccordionDetails>
                          <Profile user ={profile} isAuthenticated={props.isAuthenticated} UpdateProfileSubmit={onUpdateProfileSubmit}/>
                              {/* <EditProfile onSubmit={this.submitUpdateProfile} companyList={this.state.companies}/> */}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={changePasswordPanel === true} onChange={handleChangePanel.bind(this,'panel2')} style={{marginBottom:'100px'}}>
                        <AccordionSummary
                        expandIcon={changePasswordPanel? <RemoveCircleIcon /> : <AddCircleIcon />}
                        aria-controls="change-password-content"
                        id="change-password-header"
                        >
                        <Typography>Change Password</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                              <ChangePassword user ={profile} isAuthenticated={props.isAuthenticated} ChangePasswordSubmit={onChangePasswordSubmit}/>
                        </AccordionDetails>
                    </Accordion>
               </>
        
    </Container>
    </main>
    <Footer />
    </ThemeProvider>
</div>)
}