import React, { Component } from 'react'
import HomeSEO from '../../components/SEO/Home'
import Header from '../../components/Header/Header'
import InventorySearchForm from '../../components/Home/InventorySearchForm'
import RecentVehicles from '../../components/Teasers/RecentVehicles'
import BodyStylesList from '../../components/Teasers/BodyStylesList'
import MakesList from '../../components/Teasers/MakesList'
import ModelsList from '../../components/Teasers/ModelsList'
import Footer from '../../components/Footer/Footer'

import * as api from '../../api/vehicleApi'

import Container from '@material-ui/core/Container'

import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
    backdrop: {
        zIndex: 999999999999,
        color: '#fff',
        background: "black"
      },
});
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            recentVehicles:[],
            badges:[],
            makes:[],
            models:[],
            newBodyStyles:[],
            usedBodyStyles:[],
            newMakes:[],
            usedMakes:[],
            allMakes:[],
            newModels:[],
            usedModels:[],
            newYears:[],
            usedYears:[],
            selectedMake:"",
            selectedYear:"",
            selectedCondition:"",
            selectedModel:"",
            sorted_column: 'date',
            order: 'desc',
            search_params:{condition:'',year:'',color:'',body:'',transmission:'',make:'',model:''},
            total:0,
            pageNumber:0,
            open:false,
        }
    }
    componentDidMount() {
        api.getFilterData().then((response)=>{
            this.setState({makes:response.data.make,isLoading:false});
            this.setState({models:response.data.model,isLoading:false});
        })

        api.getNewFilterData().then((response)=>{ 
            this.setState({newBodyStyles:response.data.body,isLoading:false});
            this.setState({newMakes:response.data.make,isLoading:false});
            this.setState({newModels:response.data.model,isLoading:false});
            this.setState({newYears:response.data.year,isLoading:false});
        })

        api.getUsedFilterData().then((response)=>{
            this.setState({usedBodyStyles:response.data.body,isLoading:false});
            this.setState({usedMakes:response.data.make,isLoading:false});
            this.setState({usedModels:response.data.model,isLoading:false});
            this.setState({usedYears:response.data.year,isLoading:false});
        })

        api.getRecentVehicles(6).then((response)=> {
            this.setState({recentVehicles:response.data.vehicles,isLoading:false});
        })

        api.getBadgesList().then((response)=> {
            this.setState({badges:response.data,isLoading:false,open:true});
        })
        this.setState({isLoading:true,open:false});
        this.setState({isLoading:false,open:true});
        window.scrollTo(0, 0)
    }
    handleSearch(data) { 
        if(data.year){
            this.setState({selectedYear:data.year});
        }    
        if(data.make){
            this.setState({selectedMake:data.make});
        } 
        if(data.model){
            this.setState({selectedModel:data.model});
        }     
    }

    handleSubmit(event, value){
        let queryString=null;
        if(value === 'used'){
            queryString = '/used-vehicles';

            let i = 0;
            if(this.state.selectedYear){
                if(i === 0){
                    queryString = queryString + '?year='+this.state.selectedYear;
                }else{
                    queryString = queryString + '&year='+this.state.selectedYear;
                }
                i++;
            }

            if(this.state.selectedMake){
                if(i === 0){
                    queryString = queryString + '?make='+this.state.selectedMake;
                }else{
                    queryString = queryString + '&make='+this.state.selectedMake;
                }
                i++;
            }
            if(this.state.selectedModel){
                if(i === 0){
                    queryString = queryString + '?model='+this.state.selectedModel;
                }else{
                    queryString = queryString + '&model='+this.state.selectedModel;
                }
               i++;
            }
            
        }else{
            queryString = '/new-vehicles';
            
            let i = 0;
            if(this.state.selectedYear){
                if(i === 0){
                    queryString = queryString + '?year='+this.state.selectedYear;
                }else{
                    queryString = queryString + '&year='+this.state.selectedYear;
                }
                i++;
            }

            if(this.state.selectedMake){
                if(i === 0){
                    queryString = queryString + '?make='+this.state.selectedMake;
                }else{
                    queryString = queryString + '&make='+this.state.selectedMake;
                }
                i++;
                
            }
            if(this.state.selectedModel){
                if(i === 0){
                    queryString = queryString + '?model='+this.state.selectedModel;
                }else{
                    queryString = queryString + '&model='+this.state.selectedModel;
                }
                i++;
                
            }
        }
        this.props.history.push(queryString);
    }
    handleClose() {
        this.setState({open:false}); 
    }
    render() {
        const { classes } = this.props;
        return(
            <ThemeProvider theme={whrepoTheme}>
                <HomeSEO />
                {this.state.isLoading?
                (
                <Backdrop  open={this.state.open} onClick={this.handleClose.bind(this)} className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                ):null}
                <Header user={this.props.user} isAuthenticated={this.props.isAuthenticated}/>
                <main>
                <Container>
                    <InventorySearchForm 
                     newMakes={this.state.newMakes} 
                     newModels={this.state.newModels}
                     newYears={this.state.newYears}
                     usedMakes={this.state.usedMakes} 
                     usedModels={this.state.usedModels}
                     usedYears={this.state.usedYears}
                     handleSearch={this.handleSearch.bind(this)}
                     handleSubmit={this.handleSubmit.bind(this)}
                    />
                    <RecentVehicles recentVehicles={this.state.recentVehicles} badges={this.state.badges} isLoading={this.state.isLoading} />
                    <BodyStylesList usedBodyStyles={this.state.usedBodyStyles} newBodyStyles={this.state.newBodyStyles} isLoading={this.state.isLoading}/>
                    <MakesList usedMakes={this.state.usedMakes} newMakes={this.state.newMakes} isLoading={this.state.isLoading}/>
                    <ModelsList usedModels={this.state.usedModels} newModels={this.state.newModels} isLoading={this.state.isLoading}/>
                    
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
            
        )
    }
}

export default withStyles(styles)(Home);