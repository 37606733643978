import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../assets/jss/components/teaserTabStyle.js'
const useStyles = makeStyles(styles);
function ModelsList(props) {
    //console.log(props.models);
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className={classes.homeRow}>
            <Typography component="h2" variant="h4" gutterBottom>Browse by Model</Typography>
            {!props.isLoading? (  
            <div className={classes.Tabs}>
            
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="model tabs">
                <Tab label="Used" {...a11yProps(0)} />
                <Tab label="New" {...a11yProps(1)} />
                </Tabs>
           
            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2} alignItems="center">
                {props.usedModels.map((model)=>{
                    return (
                            <Grid item xs={6} sm={4} md={2} key={model.id}>  
                            <NavLink className={classes.link} to={'/used-vehicles/?model='+model.model_name}>
                                <Typography component="h3" variant="subtitle1">{model.model_name} <Chip variant="default" size="small" label={model.count} className={classes.chipCount}/></Typography> 
                            </NavLink>
                        </Grid>
                            
                    )
                })} 
                {props.usedModels.length===0?<Grid item xs={12}>No used vehicles listed yet. Be the first one!</Grid>:null}
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2} alignItems="center">
                {props.newModels.map((model)=>{
                    return (
                            <Grid item xs={6} sm={4} md={2} key={model.id}>  
                            <NavLink className={classes.link} to={'/new-vehicles/?model='+model.model_name}>
                                <Typography component="h3" variant="subtitle1">{model.model_name} <Chip variant="default" size="small" label={model.count} className={classes.chipCount}/></Typography> 
                            </NavLink>
                        </Grid>
                            
                    )
                })}
                {props.newModels.length===0?<Grid item xs={12}>No new vehicles listed yet. Be the first one!</Grid>:null}
                </Grid>
            </TabPanel>
            </div>
            ) : (
                <Grid item xs={12}>
                    <CircularProgress color="secondary" />
                    <p>Loading...</p>
                </Grid>
              )}
            

        </div>
    )
}
export default ModelsList;

function a11yProps(index) {
    return {
      id: `models-tab-${index}`,
      'aria-controls': `models-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`models-tabpanel-${index}`}
        aria-labelledby={`models-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} >
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }