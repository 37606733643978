import React from 'react'
import { Helmet } from 'react-helmet'

export default function NotFoundSEO() {
  return (
    <>
        <Helmet>
            <title>Page not found &#8226; Wheels Repository  </title>
            <meta name="description" content="Page you are looking for does not exist." />
        </Helmet>
    </>
  )
}

