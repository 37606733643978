import axios from 'axios';
import {BASE_URL} from '../utils';
const base_url = BASE_URL;
//const base_url = `http://127.0.0.1:8000`

//const csrfToken = document.querySelector('[name=csrf-token]').content
//axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

const axiosInstance = axios.create({
    baseURL: base_url,
    //headers: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
});


export default axiosInstance;