import React from 'react'
import { Helmet } from 'react-helmet'

export default function DealerSignUpSEO() {
  return (
        <Helmet>
            <title>Dealer Sign Up &#8226; Wheels Repository  </title>
            <meta name="description" content="Sign up for Vehicle or Car Dealership Account, manage your vehicles online, connect with potential buyers easily in Nepal at whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/dealer-signup/" />
        </Helmet>
  )
}