import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    root: {
     // padding: theme.spacing(1, 1),
      textAlign: "right",
      padding: "10px"
    },
    sortBy:{
        float:"left",
        width:170,
        textAlign:"left"
    }
  }));

function SortingBar(props){
    const classes = useStyles();
    const [sort, setSort] = React.useState('');
    const handleChange = event => {
        //setAge(event.target.value);
        const sortBy = event.target.value;
        if(sortBy === 10){
            setSort(10);
            props.handleSortBy('PA');
        }else if(sortBy === 20){
            setSort(20);
            props.handleSortBy('PD');
        }else if(sortBy === 30){
            setSort(30);
            props.handleSortBy('YA');
        }else if(sortBy === 40){
            setSort(40);
            props.handleSortBy('YD');
        }else{
            setSort('');
            props.handleSortBy('DD');
        }
      };
    return(
        <>
            <Paper className={classes.root}>
                <Typography component={'div'}>
                <FormControl className={classes.sortBy}>
                    <InputLabel id="wr-sort-select-label">Sort By</InputLabel>
                    <Select
                    labelid="wr-sort-select-label"
                    id="wr-sort-select"
                    value={sort}
                    onChange={handleChange}
                    autoWidth
                    >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value={10}>Price Ascending</MenuItem>
                    <MenuItem value={20}>Price Descending</MenuItem>
                    <MenuItem value={30}>Year Ascending</MenuItem>
                    <MenuItem value={40}>Year Descending</MenuItem>
                    </Select>
                </FormControl>
                <IconButton onClick={function(){props.handleViewType("List")}} aria-label="list">
                    <ViewListIcon />
                </IconButton>
                <IconButton onClick={function(){props.handleViewType("Module")}} aria-label="grid">
                    <ViewModuleIcon />
                </IconButton>
                </Typography>
            </Paper>
        </>
    )
}

export default SortingBar;