import React from 'react'
import AboutSEO from '../../components/SEO/About'
import Header from '../../components/Header/Header'
import SimpleBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

const useStyles = makeStyles(theme => ({
    main:{
        marginTop: 100,
        minHeight: "calc(100vh - 341px)",
    },
    textCenter:{
        textAlign:"center"
    }
}));

const About = (props) => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <ThemeProvider theme={whrepoTheme}>
            <AboutSEO />
            <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
            <main className={classes.main}>
            <Container>
                <SimpleBreadcrumbs />
                <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                    About
                </Typography>
                <Typography variant="body1">
                Wheels Repository is an online marketplace to buy and sell vehicles connecting car buyers and car dealers from all over Nepal.<br></br> 
                Whether you are a car dealership or an individual (a private owner) looking to sell your car(s), list them and reach out to potential buyers.<br></br>
                Browse new and used cars, find local dealers or sellers, search by vehicle models, specifications, and features, narrow results by price range, get the best pricing and more.
                Wheels Repository simplifies buying and selling cars.
                </Typography>

            </Container>
            </main>
            <Footer />
        </ThemeProvider>
    )
}

export default About;