import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import {BASE_URL, CURRENCY} from '../../../utils';
import { NavLink } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoreIcon from '@material-ui/icons/Store';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
    paper: {
     margin: theme.spacing(0,0,2,0),
    },
    badges: {
        position: "absolute",
        right:0,
        bottom:0,
        display: 'flex',
        justifyContent:'flex-start',
        //flexDirection:"column-reverse",
        flexWrap: 'wrap',
        '& > *': {
          //margin: theme.spacing(0.2),
          margin: 1,
          height:18,
          borderRadius:2,
          fontSize:12,
          fontWeight:"bold",
        },
      },
      badges1: {
        top:0,
        bottom: "inherit",
      },
      usedBadge:{
        "& .MuiChip-root":{
        backgroundColor: theme.palette.primary.main,
        color:"white",
        }
    },
    newBadge:{
        "& .MuiChip-root":{
        backgroundColor: theme.palette.secondary.main,
        color:"white",
        }
    },
      tbody:{
          display:"inline-block",
      },
      locIcon:{
        fontSize:12,
    }
    
  }));

const renderMainImage = (images => {
    
    let path = "/images/wr-vehicle-dummy1.jpg";
    if(images && images.length>0){
        images.forEach((image)=> {
            if(image.main_flag==="1"){
                var res = image.path.split(".");
                path = `${BASE_URL}/storage/whrepo/${res[0]}-150X150.${res[1]}`;
            }
        });
    }    
    return path;
 });

const DealerVehicleListingItem = (props) => {
    const classes = useStyles();
    const vehicle = props.vehicle;
    const conditionBadge = (vehicle.condition === "New")?classes.newBadge:classes.usedBadge;
    const otherBadgeIDs = vehicle.badge_ids?vehicle.badge_ids.split(","):null;
    let url="";
    if(props.vType === "New"){
        url="new-vehicle";
    }else{
        url="used-vehicle";
    }
 
    let gridClass = '';
    if(props.viewType === "List"){
        gridClass = '';
    }else{
        gridClass = 'wr-grid-view';
    }
    const attributesList = [];
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.year)){
        attributesList.push({name: 'Year', slug: 'wr-year', value: vehicle.vehicle_info.year});
    }
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.make_id)){
        attributesList.push({name: 'Make', slug: 'wr-make', value: vehicle.vehicle_info.make_id.make_name});
    }
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.model_id)){
        attributesList.push({name: 'Model', slug: 'wr-model', value: vehicle.vehicle_info.model_id.model_name});
    }
    if(vehicle.attribute.body_id){
        attributesList.push({name: 'Body', slug: 'wr-body', value: vehicle.attribute.body_id.body_name});
    }
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.enginesize_id)){
        attributesList.push({name: 'Engine', slug: 'wr-engine', value: vehicle.vehicle_info.enginesize_id.enginesize_name});
    }
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.drive_id)){
        attributesList.push({name: 'Drivetrain', slug: 'wr-drivetrain', value: vehicle.vehicle_info.drive_id.drive_name});
    }
    if(vehicle.kms){
        attributesList.push({name: 'Kms', slug: 'wr-kms', value: vehicle.kms});
    }
    if(vehicle.attribute.fuel_capacity){
        attributesList.push({name: 'Fuel Capacity', slug: 'wr-fuel-capacity', value: vehicle.attribute.fuel_capacity+'L'});
    }
    if(vehicle.attribute.exteriorcolor_id){
        attributesList.push({name: 'Exterior Color',slug: 'wr-exterior-color', value: vehicle.attribute.exteriorcolor_id.color_name});
    }
    if(vehicle.attribute.interiorcolor_id){
        attributesList.push({name: 'Interior Color',slug: 'wr-interior-color', value: vehicle.attribute.interiorcolor_id.color_name});
    }
    if((vehicle.vehicle_info)&&(vehicle.vehicle_info.vin)){
        attributesList.push({name: 'VIN', slug: 'wr-vin', value: vehicle.vehicle_info.vin});
    }
    if(vehicle.stock_number){
        attributesList.push({name: 'Stock #', slug: 'wr-stock-number', value: vehicle.stock_number});
    }
    const attributesList1JSX = 
    attributesList.map((attribute,i) =>
    (i<6)?<tr className={`wr-attribute ${attribute.slug}`} key={i}><th><span>{attribute.name}</span></th><td><span>{attribute.value}</span></td></tr>:null
    );
    const attributesList2JSX = 
    attributesList.map((attribute,i) =>
    (i>5)?<tr className={`wr-attribute ${attribute.slug}`} key={i}><th><span>{attribute.name}</span></th><td><span>{attribute.value}</span></td></tr>:null
    );
    const vehicleTitleTrim = vehicle.title.trim();
    const vehicleTitle = vehicleTitleTrim.toLowerCase();
    const slug = vehicleTitle.replace(/ /g, "-")+"-"+vehicle.stock_number.toLowerCase();
    return (       
        <div className={gridClass} >
        <Paper className={classes.paper} >
            <NavLink className="wr-vehicle-link" to={`/${url}/${slug}/${vehicle.id}/`}>
                <div className="wr-vehicle-item">
                    <h2 className="wr-vehicle-title wr-grid-vehicle-title">{vehicle.title}</h2>
                    <div className="wr-vehicle-image">                        
                        <img src={renderMainImage(vehicle.images)} alt={vehicle.title} />
                        <div className={`${classes.badges} ${classes.badges1} ${conditionBadge}`}>
                            <Chip label={vehicle.condition} size="small" />
                        </div>
                        <div className={classes.badges}>
                                {props.badges.map((badge)=>{
                                    const bgColor = (badge.bg_color_code)?badge.bg_color_code:"inherit";
                                    const txtColor = (badge.txt_color_code)?badge.txt_color_code:"inherit";
                                    const hasBadgeID = otherBadgeIDs?otherBadgeIDs.includes(`${badge.id}`):false;
                                    if(hasBadgeID){
                                        return(
                                        <Chip key={badge.id} label={badge.badge_name} size="small" style={{backgroundColor:bgColor,color:txtColor}}/>
                                        )
                                    }else{
                                        return null;
                                    }

                                })} 
                        </div>
                    </div>
                    
                    <div className="wr-vehicle-info">

                        <h2 className="wr-vehicle-title">{vehicle.title}</h2>
                        <div className="clearfix"></div>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={6} sm={7} md={6} lg={8}>
                                <Grid container>
                                    <Grid item xs={12} lg={4}>
                                    <table className="wr-attributes-col">
                                        <tbody>
                                            {attributesList1JSX} 
                                        </tbody>
                                    </table>
                                    </Grid>
                                    <Hidden mdDown>
                                    <Grid item xs={12} lg={8}>
                                        <table className="wr-attributes-col">
                                            <tbody>
                                                {attributesList2JSX} 
                                            </tbody>
                                        </table>
                                    </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={5} md={6} lg={4}>
                                <Grid container>
                                    <Hidden smDown>
                                    <Grid item xs={12} md={7}>
                                        <div className="wr-vehicle-by-col">
                                            <div className="wr-listed-by" title="Listed by">
                                            {/* {renderCompanyLogo(vehicle.company)} */}
                                            {vehicle.company?<><StoreIcon className={classes.locIcon} fontSize="small" /> {vehicle.company.name}</>:<><PersonIcon className={classes.locIcon} fontSize="small" /> Private Owner</>}
                                            </div>
                                            {vehicle.location?<div className="wr-listed-by wr-location" title="Location"><LocationOnIcon className={classes.locIcon} fontSize="small" /> {vehicle.location.city}</div>:null}
                                            {/* <div className="wr-history-by"><span>History by:</span><br />
                                                <img src="/images/carfax-canada-logo.jpg" alt="Carfax" />
                                            </div> */}
                                        </div>
                                    </Grid>
                                    </Hidden>
                                    <Grid item xs={12} md={5}>
                                        <div className="wr-price-info-col">
                                            <div className="wr-price-figure">
                                                <del>{vehicle.price ?<span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.price}</span> : null}</del>
                                                <ins>{vehicle.selling_price ? <span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.selling_price}</span> : null}</ins>
                                                {vehicle.price || vehicle.selling_price ?<div className="wr-tax-info">Plus Sales Tax</div>:<div className="wr-tax-info">Contact for Price</div>}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </NavLink>
        </Paper>
        </div>
    
    ); 
    
    
    
}

export default DealerVehicleListingItem;