import React, { useState } from 'react'
import useForm from "react-hook-form"

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
 form: {
   width: '100%', // Fix IE 11 issue.
   marginBottom: 0
 },
 submit: {
   margin: theme.spacing(2, 0, 0),
 },

}));
function CalculatorHookForm() {
  const classes = useStyles();
  // Initialise the hook
  // Register your input into the hook by invoking the 'register' function
  // Handle-Submit will validate your input before invoking 'onSubmit'
  // Errors is an object provided by Hook Form you can use to show errors while validating inputs

  const { register, handleSubmit, errors, watch } = useForm();

  // state to storage the results of the calculation
  const [results, setResults] = useState({
    monthlyPaymentUI: '',
    totalPaymentUI: '',
    totalInterestUI: '',
    isResult: false,
    userInitialData: '',
  });

  // Hook-form registered fields returns a object with key(name): value, which is sent to the calculateResults
  const onSubmit = (data, e) => {
    calculateResults(data);
    e.target.reset();
  };

  // Calculation
  const calculateResults = ({ amount, interest, years }) => {
    const userAmount = parseFloat(amount),
      calculatedInterest = parseFloat(interest) / 100 / 12,
      calculatedPayments = parseFloat(years) * 12,
      x = Math.pow(1 + calculatedInterest, calculatedPayments),
      monthly = (userAmount * x * calculatedInterest) / (x - 1);

    if (isFinite(monthly)) {
      const monthlyPayment = monthly.toFixed(2),
        totalPayment = (monthly * calculatedPayments).toFixed(2),
        totalInterest = (monthly * calculatedPayments - userAmount).toFixed(2);

      // Set up results to the state to be displayed to the user
      const newResults = { ...results };
      newResults.monthlyPaymentUI = monthlyPayment;
      newResults.totalPaymentUI = totalPayment;
      newResults.totalInterestUI = totalInterest;
      newResults.isResult = true;
      newResults.userInitialData = (
        <Typography>
          {' '}
          Loan amount: NPR {amount} <br /> Interest: {interest}% <br /> Years to
          repay: {years}{' '}
        </Typography>
      );
      setResults(newResults);
    }
    return;
  };

  // Clear input fields
  const clearFields = () => {
    const newResults = { ...results };
    newResults.monthlyPaymentUI = '';
    newResults.totalPaymentUI = '';
    newResults.totalInterestUI = '';
    newResults.isResult = false;
    setResults(newResults);
  };

  //HOOK-FORM
  // Each field is required to have a unique name, which will be the key for the registration process

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        {/* Form to collect data from the user */}
        {!results.isResult && (
          <div className='form-items'>
            <div>
              
              <TextField
                id="amount"
                label="Loan amount"
                name="amount"
                fullWidth
                error={!!errors.amount}
                inputRef={register({
                required: 'Required',
                pattern: {value:/^[0-9]+$/,
                message:'Invalid amount.'},
                maxLength: {value:8,message:'Number exceeded!'}
                })}
                />
                {errors.amount && (errors.amount.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.amount.message}</Typography>:''}
            </div>
            <div>
              
              <TextField
                id="interest"
                label="Interest %"
                name="interest"
                fullWidth
                error={!!errors.interest}
                inputRef={register({
                required: 'Required',
                pattern: {value:/^[0-9]+$/,
                message:'Invalid interest.'},
                })}
                />
                {errors.interest && (errors.interest.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.interest.message}</Typography>:''}
                {watch("interest") < 0 || watch("interest") > 100?<Typography variant="caption" display="block" color="error" align="left">Invalid interest rate</Typography>:''}

            </div> 
            <div>
            <TextField
                id="years"
                label="Years to repay"
                name="years"
                fullWidth
                error={!!errors.years}
                inputRef={register({
                required: 'Required',
                pattern: {value:/^[0-9]+$/,
                message:'Invalid.'},
                min:1,
                max:100,
                })}
                />
                {errors.years && (errors.years.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.years.message}</Typography>:''}
            </div>
            <Button 
              className={classes.submit} 
              type="submit" 
              color="primary" 
              variant="contained" 
            >
              Submit
            </Button>
          </div>
        )}
        {/* Form to display the results to the user */}
        {results.isResult && (
          <div className='form-items'>
            {results.userInitialData}
            <div>
              <TextField 
                label="Monthly Payment"
                defaultValue={results.monthlyPaymentUI} 
                InputProps={{
                  readOnly: true,
                }} 
                disabled
              />
            </div>
            <div>
              <TextField 
                label="Total Payment"
                defaultValue={results.totalPaymentUI} 
                InputProps={{
                  readOnly: true,
                }} 
                disabled
              />
            </div>
            <div>
              <TextField 
                label="Total Interest"
                defaultValue={results.totalInterestUI} 
                InputProps={{
                  readOnly: true,
                }} 
                disabled
              />
            </div>

            {/* Button to clear the fields */}
            <Button 
            className={classes.submit} 
            color="primary" 
            variant="contained"
            onClick={() => clearFields()}
            >
              Calculate again
            </Button>
          </div>
        )}
      </form>
    </>
  );
}

export default CalculatorHookForm;
