import React from "react";
import { NavLink } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
    deskMenu:{
        "& .MuiListItem-root":{
            paddingTop:2,
            paddingBottom:2,
            fontSize:14,
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:hover":{
                color: theme.palette.secondary.main,
                textDecoration: "none", 
            }

        },
        "& .register-link.MuiListItem-root":{
            border: "1px solid #d81f58",
            color: theme.palette.secondary.main,
            borderRadius: 2,
            "&:hover":{
                backgroundColor:"#d81f58",
                color:"white",
            }
        }

    },
    
    
}));

function DesktopMenu(props) {
    const classes = useStyles();
    if (isWidthUp('md', props.width)){
    return (
            <List className={`${classes.deskMenu} wr-main-navigation`}>
                <li><ListItem component={NavLink} to="/"><ListItemText primary="Home"/></ListItem></li>
                {/* <li><ListItem component={NavLink} to="/inventory"><ListItemText primary="Vehicles"/></ListItem></li>
                <li><ListItem component={NavLink} to="/vehicles"><ListItemText primary="All Vehicles"/></ListItem></li> */}
                <li><ListItem component={NavLink} to="/used-vehicles/"><ListItemText primary="Used Vehicles"/></ListItem></li>
                <li><ListItem component={NavLink} to="/new-vehicles/"><ListItemText primary="New Vehicles"/></ListItem></li>
                <li><ListItem component={NavLink} to="/dealers/"><ListItemText primary="Dealers"/></ListItem></li>
                <li><ListItem component={NavLink} to="/sell-your-vehicle/"><ListItemText primary="Sell Vehicle"/></ListItem></li>
                
                {!props.isAuthenticated?
                <>
                <li><ListItem component={NavLink} to="/login/"><ListItemText primary="Log In"/></ListItem></li>
                <li><ListItem component={NavLink} to="/register/" className="register-link"><ListItemText primary="Register"/></ListItem></li>
                </>
                :null
                 }
            </List>
    )
    }else{
        return null;
    }

}
export default withWidth()(DesktopMenu);