import React from 'react'
import { Helmet } from 'react-helmet'

export default function NewVehiclesSEO() {
  return (
        <Helmet>
            <title>New Vehicles &#8226; Latest Cars in Nepal &#8226; Wheels Repository</title>
            <meta name="description" content="Browse new vehicles, latest car prices, recently launched cars, refine and analyze by specs to find the best deals in the city near you." />
            <link rel="canonical" href="https://whrepo.com/new-vehicles/" />
        </Helmet>
  )
}

