import React, { Component } from 'react'

import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import SidebarFilter from '../../components/InventoryListing/SidebarFilter/SidebarFilter'
import SortingBar from '../../components/InventoryListing/SortingBar/SortingBar'
import ResetFilterBar from '../../components/InventoryListing/ResetFilterbar/ResetFilterBar'
import ListingItem from '../../components/InventoryListing/ListingItem/ListingItem'
import Footer from '../../components/Footer/Footer'
import * as api from '../../api/vehicleApi'
import {NO_OF_ROWS} from '../../utils'
import TablePaginationActions from '../../components/InventoryListing/TablePaginationActions/TablePaginationActions'

import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import {ThemeProvider } from '@material-ui/core/styles'

import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'
import vehicleListStyle from '../../assets/jss/components/vehicleListStyle'

const useStyles = vehicleListStyle

class InventoryListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:true,
            vehicles:[],
            filtered:[],
            keyword:'',
            makes:[],
            models:[],
            transmissions:[],
            bodies:[],
            colors:[],
            years:[],
            sorted_column: 'id',
            order: 'desc',
            search_params:{condition:'',year:'',color:'',body:'',transmission:'',make:'',model:''},
            total:0,
            pageNumber:0,
            viewType:"List", //Module
            badges:[]
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleViewType = this.handleViewType.bind(this);
        
    }
    componentDidMount() {
        this.setState({isLoading:true});
        api.getFilterData().then((response)=>{
            this.setState({makes:response.data.make});
            this.setState({models:response.data.model});
            this.setState({transmissions:response.data.transmission});
            this.setState({bodies:response.data.body});
            this.setState({colors:response.data.color});
            this.setState({years:response.data.year});
        })

        api.getBadgesList().then((response)=>{
            this.setState({badges:response.data});
        })
        
        if(this.props.match.params.search !== undefined){
            let querySearch = this.props.match.params.search;
            let result = querySearch.split("&");
            let paramsObj = {};
            for(let i=0;i<result.length;i++){
                let temp = result[i].split("=");
                paramsObj[temp[0]]=temp[1];
            }
            if(paramsObj['page']){
                this.setState({pageNumber:paramsObj['page']-1});
            }
            let queryObj ={condition:'',year:'',color:'',body:'',transmission:'',make:'',model:''};
            var searchParams = {...this.state.search_params}

            if(paramsObj['condition']){                
                searchParams.condition = paramsObj['condition'];
                queryObj.condition = paramsObj['condition'];
            }
            if(paramsObj['year']){
                searchParams.year = paramsObj['year'];
                queryObj.year = paramsObj['year'];
            }
            if(paramsObj['make']){
                searchParams.make = JSON.parse(paramsObj['make']);
                queryObj.make = JSON.parse(paramsObj['make']);
            }
            if(paramsObj['model']){
                searchParams.model = JSON.parse(paramsObj['model']);
                queryObj.model = JSON.parse(paramsObj['model']);
            }

            if(paramsObj['transmission']){
                searchParams.transmission = JSON.parse(paramsObj['transmission']);
                queryObj.transmission = JSON.parse(paramsObj['transmission']);
            }

            if(paramsObj['body']){
                searchParams.body = JSON.parse(paramsObj['body']);
                queryObj.body = JSON.parse(paramsObj['body']);
            }

            if(paramsObj['color']){
                searchParams.color = JSON.parse(paramsObj['color']);
                queryObj.color = JSON.parse(paramsObj['color']);
            }
                
           this.setState({ search_params: searchParams }); 
            api.getVehiclesList(1,this.state.sorted_column,this.state.order,JSON.stringify(queryObj)).then((response)=> {
                this.setState({vehicles:response.data.result,total:response.data.total});
            })
        }else{
            api.getVehiclesList(1,this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined).then((response)=> {
                this.setState({vehicles:response.data.result,total:response.data.total});
            })
        }
        window.scrollTo(0, 0)
        
    }
    searchVehicle(event){
        const keyword = event.target.value;

        if(keyword !== ''){
             const list = this.state.vehicles.filter((vehicle)=>{
                return vehicle.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
             })
             console.log("count"+list.length)
             this.setState({ 
                filtered:list,
                total: list.length,
                keyword
             })
             console.log(this.state.filtered)
        }else{
            this.setState({
                filtered:this.state.vehicles,
                 keyword
             })
        }
        
    }

    handleSidebarFilterSubmit(values) { 
        this.setState({ search_params: values },this.getVehicleListings); 
    }

    handleSortingBarSubmit(values) {
        let column = 'id';
        let order = 'desc';
        if(values === 'PA'){
            column = 'price';
            order = 'asc';
        }else if(values === 'PD'){
            column = 'price';
            order = 'desc';
        }else if(values === 'YA'){
            column = 'year';
            order = 'asc';
        }else if(values === 'YD'){
            column = 'year';
            order = 'desc';
        }else if(values === 'DD'){
            column = 'id';
            order = 'desc';
        }else{
            column = 'id';
            order = 'desc';
        }
        this.setState({ sorted_column: column,order: order },this.getVehicleListings);
    }

    handleViewType(type) {
        this.setState({viewType:type});

    }

    getVehicleListings(){
        let queryString = '/vehicles/';
            
        if(this.state.search_params.condition){
            queryString = queryString + '&condition='+this.state.search_params.condition;
        }
        if(this.state.search_params.year){
            queryString = queryString + '&year='+this.state.search_params.year;
        }
        if(this.state.search_params.make){
            queryString = queryString + '&make='+JSON.stringify(this.state.search_params.make);
        }
        if(this.state.search_params.model){
            queryString = queryString + '&model='+JSON.stringify(this.state.search_params.model);
        }
        if(this.state.search_params.transmission){
            queryString = queryString + '&transmission='+JSON.stringify(this.state.search_params.transmission);
        }
        if(this.state.search_params.body){
            queryString = queryString + '&body='+JSON.stringify(this.state.search_params.body);
        }
        if(Array.isArray(this.state.search_params.color) && this.state.search_params.color.length > 0 ){
            queryString = queryString + '&color='+JSON.stringify(this.state.search_params.color);
        }
        this.props.history.push(queryString);
      
            api.getVehiclesList(1,this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined).then((response)=> {
                this.setState({vehicles:response.data.result,total:response.data.total});
            })
    }

    deleteFilter(filter,id){
        const { search_params } = { ...this.state };
        const currentSearch = search_params;
        
        if(filter === "condition"){
            currentSearch['condition'] = "";
        }else if(filter === "year"){
            currentSearch['year'] = "";
        }else if(filter === "make"){
            currentSearch['make'] = "";
        }else if(filter === "model"){
            currentSearch['model'] = "";
        }else if(filter === "transmission"){
            currentSearch['transmission'] = "";
        }else if(filter === "body"){
            currentSearch['body'] = "";
        }else if(filter === "color"){
            let c = currentSearch["color"].filter(function(item)
                {
                    return item.id !== id;
                });

            currentSearch['color'] = c ? c : "";
        }else{
            currentSearch['condition'] = "";
            currentSearch['year'] = "";
            currentSearch['make'] = "";
            currentSearch['model'] = "";
            currentSearch['transmission'] = "";
            currentSearch['body'] = "";
            currentSearch['color']="";
        }
        this.setState({ search_params: currentSearch } ,this.getVehicleListings);       

    }
    handleChangePage(event,page){
        this.setState({isLoading:true});
        api.getVehiclesList((page+1),this.state.sorted_column,this.state.order,this.state.search_params?JSON.stringify(this.state.search_params):undefined).then((response)=> {
            this.setState({vehicles:response.data.result,pageNumber:page});
        })
    }
    
    render() {
        const { classes } = this.props;
        let gridParentClass = '';
        if(this.state.viewType !== "List"){
            gridParentClass = 'wr-grid-listing';
        }
        return(
            <ThemeProvider theme={whrepoTheme}>
                 <Header page={this.state.pageNumber} search={this.state.search_params} isAuthenticated={this.props.isAuthenticated}/>
                 <main className={classes.main}>
                <Container>
                    <Breadcrumbs />
                    <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                        Inventory Listing
                    </Typography>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                        <Paper className={classes.SidebarPaper}>
                            <SidebarFilter 
                            makes={this.state.makes} 
                            models={this.state.models}
                            transmissions={this.state.transmissions} 
                            bodies={this.state.bodies}
                            colors={this.state.colors}
                            years={this.state.years}
                            handleFilter={this.handleSidebarFilterSubmit.bind(this)}
                            search_params={this.state.search_params}
                            keyword={this.state.keyword}
                            handleSearch={this.searchVehicle.bind(this)}
                            />
                        </Paper>
                        </Grid>
                        <Grid item xs={9}>
                            {this.state.search_params.condition || 
                             this.state.search_params.year ||
                             this.state.search_params.body || 
                             this.state.search_params.make ||
                             this.state.search_params.model || 
                             this.state.search_params.transmission ||
                             this.state.search_params.color ?(<ResetFilterBar search_params={this.state.search_params} deleteFilter={this.deleteFilter.bind(this)}/>):""
                            }
                            <Paper className={classes.paper} spacing={3}>
                                <Typography component="h4" color="inherit" variant="h5">
                                    {this.state.total} {this.state.total>1?"Vehicles":"Vehicle"}
                                </Typography>
                            </Paper>
                            <SortingBar handleSortBy={this.handleSortingBarSubmit.bind(this)} handleViewType={this.handleViewType}/>
                            { (Object.keys(this.state.filtered).length !== 0) ?
                            <div className={classes.listContainer}>
                            <div className={`wr-listing ${gridParentClass}`}>
                                {
                                    this.state.vehicles.map((vehicle) => <ListingItem key={vehicle.id}
                                                                            vehicle={vehicle}
                                                                            viewType={this.state.viewType}
                                                                            badges={this.state.badges} 
                                                                            vType = "New"
                                                                            />)
                                }
                                
                            </div>
                            </div>
                            :
                            <div className={classes.listContainer}>
                            <div className={`wr-listing ${gridParentClass}`}>
                                {
                                    this.state.vehicles.map((vehicle) => <ListingItem key={vehicle.id}
                                                                            vehicle={vehicle}
                                                                            viewType={this.state.viewType}
                                                                            badges={this.state.badges} 
                                                                            vType = "New"
                                                                            />)
                                }
                                
                            </div>
                            </div>
                            }
                            {this.state.total > NO_OF_ROWS ?
                                <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={NO_OF_ROWS}
                                page={this.state.pageNumber}
                                onChangePage={this.handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                //onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> : null
                            }
                        </Grid>
                    </Grid>
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )
    }
}
export default withStyles(useStyles)(InventoryListing)


