const teaserVehiclesStyle = theme => ({
    marginTB:{
        marginTop:theme.spacing(4),
    },
    teaserVehicles:{
        marginBottom: theme.spacing(4),
        "& .MuiCardHeader-root":{
            padding:theme.spacing(2),
        },
        "& .MuiTypography-h5":{
            fontSize: "1rem",
            margin: 0,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 5,
            height:35,
            overflow:"hidden",
            "& a":{
                color:theme.palette.primary.main,
                "&:hover":{
                    color:theme.palette.secondary.main,
                }
            }
        },
        "& .wr-price-figure":{ 
            textAlign: "right",
            minHeight: 46,
            "& del":{
                display:"block",
                textDecoration: "line-through", 
                color:"grey",
                "& .wr-price-amount":{
                    fontSize:16,
                    lineHeight: 1,
                     fontWeight:700,
                    "& .wr-currency-symbol":{
                        fontSize:10,
                    }
                }
            },
            "& ins":{
                display:"block",
                textDecoration: "none",
                color:"black",
            },
            "& .wr-price-amount":{
                fontSize:18,
                lineHeight: 1,
                 fontWeight:700,
                "& .wr-currency-symbol":{
                    fontSize:12,
                }
            },
            "& .wr-tax-info":{
                fontSize: 12,
                lineHeight: 1,
                color:"black",
                fontWeight:300,
                display:"block", 
                width:100+"%",
                overflow: "hidden",
            },
        }
        
    },
    card:{
        
        height:'100%',
    },
    badges: {
        position: "absolute",
        right:0,
        bottom:0,
        display: 'flex',
        justifyContent:'flex-start',
        flexWrap: 'wrap',
        '& > *': {
          margin: 1,
          height:18,
          borderRadius:2,
          fontSize:12,
          fontWeight:"bold",
        },
      },
      badges1: {
        top:0,
        bottom: "inherit",
      },
    usedBadge:{
        "& .MuiChip-root":{
        backgroundColor: theme.palette.primary.main,
        color:"white",
        }
    },
    newBadge:{
        "& .MuiChip-root":{
        backgroundColor: theme.palette.secondary.main,
        color:"white",
        }
    },
    media: {
        height: 0,
        position:"relative",
        paddingTop: '56.25%', // 16:9
      },
      por:{
          textTransform:"none",
          //fontSize: ,
        lineHeight: 1,
        //fontWeight: 400,
        //color:"#3a3a3c",
      }
});

  export default teaserVehiclesStyle;