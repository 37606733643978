import React from 'react';
import { NavLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  copyright:{
    color: 'rgba(255,255,255,0.7)',
    margin: 0,
    paddingTop: '12px',
    borderTop: '1px solid #ffffff',
    "& .MuiTypography-root":{
      textAlign: "right",
    }
  },
  link: {
    color: 'rgba(255,255,255,0.7)',
    '&:hover':{
      textDecoration: 'underline',
      color: 'rgba(255,255,255,0.9)',
    }
  },
  list:{
    display:"flex",
    justifyContent: "flex-start",
    "& .MuiListItem-root":{
      width:"inherit",
      marginRight: 20
    }

  },
});
const Copyright = () => {
  const classes = useStyles();
    return(
      <div className={classes.copyright}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <List dense={true} className={classes.list} disablePadding>
              <ListItem component={NavLink} to="/privacy/" disableGutters><ListItemText primary="Privacy" /></ListItem>
              <ListItem component={NavLink} to="/terms/" disableGutters><ListItemText primary="Terms" /></ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" >{'Copyright © '}<Link href="/" className={classes.link}>Wheels Repository</Link>{' '}{new Date().getFullYear()}{'.'}</Typography>
          </Grid>
          </Grid>
      </div>
      
    )
}
export default Copyright;
