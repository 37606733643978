import axios from './axiosInstance'

export function getDealers(page,city,keyword) {
    return axios.get('/api/fetchDealers', {
        params: {
        page,
        city,
        keyword
        }

    })
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
    //return axios.get(`/api/customers?page=${pageNumber}&column=${sorted_column}&order=${order}`)
}

export function getCities() {
    return axios.get('/api/fetchCities')
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
    //return axios.get(`/api/customers?page=${pageNumber}&column=${sorted_column}&order=${order}`)
}

export function getDealer(id,loc_id) {
    return axios.get('/api/fetchDealer', {
        params: {
        id,
        loc_id
        }

    })
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
    //return axios.get(`/api/customers?page=${pageNumber}&column=${sorted_column}&order=${order}`)
}

export function getDealerVehicles(page,id,loc_id, limit,orderby,order) {
    return axios.get('/api/fetchDealerVehicles', {
        params: {
        page:page,
        id,
        loc_id,
        limit,
        orderby,
        order
        }

    })
    .catch(error=> {
        console.log(error)
        return {
            errors: error
        }
    })
}