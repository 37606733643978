import React from 'react';

import useForm from "react-hook-form";

import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '24px',
        borderRadius: '0 0 2px 2px',
        marginBottom: '1rem'
      },
      button: {
          margin: '5px',
          marginLeft: 0
      },
      buttonTop:{
          margin:0,
          flex: 1,
      },
      select:{
          display: 'flex',
      },
      profileImage:{
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          display: 'flex',
          overflow: 'hidden',
          border: '1px solid #e5e5e5',
          '& img':{
              width:'150px',
              height: '150px',
              objectFit: 'contain',
          }
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },

}));


  export default function ChangePassword(props){
      //debugger;
    //const user = props.user;
    //const [error, setError] = React.useState("");
    const classes = useStyles();
    const ChangePasswordSubmit = props.ChangePasswordSubmit;
    
    const methods = useForm();
    //const { handleSubmit, getValues,register, errors,formState: { isDirty, isSubmitting, touched, submitCount } } = methods;
    const { handleSubmit, getValues,register, errors } = methods;
    // const onSubmit = (data) => {
    //     debugger;
    
    // }
    
        return (
            <form className={classes.form} noValidate onSubmit={handleSubmit(ChangePasswordSubmit)}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                                   <TextField
                                        label="Current Password"
                                        name="password"
                                        type="password"
                                        fullWidth
                                        //onChange={handleChange.bind(this)}
                                        //value={userProfile.firstName}
                                        error={!!errors.password}
                                        inputRef={register({
                                            required: 'Required',
                                            maxLength: {value:50,message:'Chars exceeded!'},
                                            //validate: value => value != 
                                        })}
                                   />
                              </Grid>
                              <Grid item xs={12}>
                                   <TextField
                                        label="New Password"
                                        name="new_password"
                                        type="password"
                                        fullWidth
                                        error={!!errors.new_password}
                                        inputRef={register({
                                            required: 'Required',
                                            maxLength: {value:50,message:'Chars exceeded!'},
                                            //validate: value => value != 
                                        })}
                                   />
                              </Grid>
                              <Grid item xs={12}>
                                   <TextField
                                        label="Retype New Password"
                                        name="c_new_password"
                                        type="password"
                                        id="c_new_password"
                                        fullWidth
                                        error={!!errors.c_new_password}
                                        inputRef={register({ 
                                            validate: {mismatch: value => value !== getValues("new_password")
                                            }})}

                                   />
                                   {errors.c_new_password?.type === "mismatch" && (
                                    <p>Password mismatch</p>
                                    )}
                              </Grid>
                         </Grid>
                         <Grid container spacing={4}>
                              <Grid item xs={12}>
                                   <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        endIcon={<SaveIcon />}
                                        //disabled={(pristine | submitting)?true:false}
                                        type="submit"
                                        title="Change Password"
                                   >
                                        Change Password
                                   </Button>
                              </Grid>
                </Grid>
            </form>
                        
    
      )
            
        
    
}
