import React, { Component } from 'react'

import InventoryDetailSEO from '../../components/SEO/InventoryDetail'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import ListingDetail from '../../components/InventoryDetail/ListingDetail/ListingDetail'
import SimilarBodyVehicles from '../../components/InventoryDetail/SimilarVehicles/SimilarBodyVehicles'
import Footer from '../../components/Footer/Footer'

import * as api from '../../api/vehicleApi'

import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
//import { LinearProgress } from '@material-ui/core'

import {ThemeProvider, withStyles } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'


const useStyles = theme => ({
    main: {
        marginTop: 100,
        minHeight: "calc(100vh - 341px)",
    },
    alert:{
        width: "100%",
        marginBottom: 20,
    }

});

class InventoryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prev:{},
            detail:{},
            next:{},
            options:[],
            optionCategories:[],
            error:null,
            success:null,
            openDialog:null,
            isLoading:true,
            userDetail:{},
            similarBodyVehicles:[],
            badges:[],

            
        }
        this.handleSubmitGetMoreInfo = this.handleSubmitGetMoreInfo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleSubmitTestDrive = this.handleSubmitTestDrive.bind(this);
        this.handleSubmitReferToFriend = this.handleSubmitReferToFriend.bind(this);

    }
    componentDidMount() {
        const location = window.location;
        let x = location.pathname.split("/");
        let id = this.props.match.params.id;

        api.getVehicleDetail(id,x[1]).then((response)=> {
            if(response.data.success){
                this.setState({detail:response.data.vehicle,prev:response.data.prev,next:response.data.next,userDetail:response.data.user_detail});
                //debugger;
                api.getSimilarVehicles(6,response.data.vehicle.condition,response.data.vehicle.attribute.body_id?response.data.vehicle.attribute.body_id.id:null,response.data.vehicle.id).then((response)=> {
                    //debugger;
                    this.setState({similarBodyVehicles:response.data.vehicles});
                })
            }else{
                //redirect to page not found
                window.location = '/404'
            }
        })
        api.getOptionList().then((response)=>{
            this.setState({options:response.data});
        })
        api.getOptionCategoryList().then((response)=>{
            this.setState({optionCategories:response.data})
        })
        

        api.getBadgesList().then((response)=> {
            this.setState({badges:response.data,isLoading:false});
        })
        this.setState({isLoading:false});
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.url !== prevProps.match.url) {
            const location = window.location;
            let x = location.pathname.split("/");
            let id = this.props.match.params.id;
            api.getVehicleDetail(id,x[1]).then((response)=> {
                if(response.data.success){
                    this.setState({detail:response.data.vehicle,prev:response.data.prev,next:response.data.next,userDetail:response.data.user_detail});
                    api.getSimilarVehicles(6,response.data.vehicle.condition,response.data.vehicle.attribute.body_id?response.data.vehicle.attribute.body_id.id:null,response.data.vehicle.id).then((response)=> {
                        this.setState({similarBodyVehicles:response.data.vehicles});
                    })
                }else{
                    //redirect to page not found
                    window.location = '/404'
                }
            })
        }
        window.scrollTo(0, 0)
    }

    handleSubmitGetMoreInfo(data){
        const w = window.location;
        data.url = w.href;
        this.setState({isLoading:true});
        api.requestMoreInfo(data).then((response)=> {
            this.setState({isLoading:false});
            if(!this.state.isLoading){
                this.setState({openDialog:null});
            }
            if(response.data.success){
                this.setState({success:"Your request has been successfully sent."});
            }else{
                this.setState({error:"Couldn't submit your request, please try again later!"});
            }

        })
        
        
    }

    handleSubmitTestDrive(data){
        const w = window.location;
        data.url = w.href;
        this.setState({isLoading:true});
        api.bookTestDrive(data).then((response)=> {
            this.setState({isLoading:false});
            if(!this.state.isLoading){
                this.setState({openDialog:null});
            }
            if(response.data.success){
                this.setState({success:"Your request has been successfully sent."});
            }else{
                this.setState({error:"Couldn't submit your request, please try again later!"});
            }

        })
        
        
    }

    handleSubmitReferToFriend(data){
        const w = window.location;
        data.url = w.href;
        this.setState({isLoading:true});
        api.referToFriend(data).then((response)=> {
            this.setState({isLoading:false});
            if(!this.state.isLoading){
                this.setState({openDialog:null});
            }
            if(response.data.success){
                this.setState({success:"Your request has been successfully sent."});
            }else{
                this.setState({error:"Couldn't submit your request, please try again later!"});
            }

        })
        
        
    }

    handleClose(){
        this.setState({success:null,error:null});
    }
    handleDialogOpen = (value) => {
        this.setState({openDialog:value});
    };

    handleDialogClose = (e) => {
        this.setState({openDialog:null});
    };
    render() {
        const { classes } = this.props;
        const currentURL = window.location.href;
        return(
            <ThemeProvider theme={whrepoTheme}>
                <InventoryDetailSEO vehicle={this.state.detail} url={currentURL} />
                <Header user={this.props.user} isAuthenticated={this.props.isAuthenticated}/>
                <main className={classes.main}>
                <Container>
                    <Breadcrumbs vehicle={this.state.detail} />
                    {this.state.error?<Alert className={classes.alert} variant="filled" severity="error" onClose={this.handleClose}>{this.state.error}</Alert>:""}
                    {this.state.success?<Alert className={classes.alert} variant="filled" severity="success" onClose={this.handleClose}>{this.state.success}</Alert>:""}
                    <ListingDetail 
                    vehicle={this.state.detail} 
                    options={this.state.options} 
                    optionCategories={this.state.optionCategories} 
                    handleSubmitRequestInfo={this.handleSubmitGetMoreInfo}
                    handleSubmitTestDrive={this.handleSubmitTestDrive}
                    handleSubmitReferToFriend={this.handleSubmitReferToFriend}
                    openDialog={this.state.openDialog}
                    handleDialogOpen={this.handleDialogOpen}
                    handleDialogClose={this.handleDialogClose}
                    isLoading={this.state.isLoading}
                    prev={this.state.prev}
                    next={this.state.next}
                    isAuthenticated={this.props.isAuthenticated}
                    userDetail={this.state.userDetail}
                    user={this.props.user}
                    />
                    {this.state.similarBodyVehicles.length > 0 ? <SimilarBodyVehicles similarBodyVehicles={this.state.similarBodyVehicles} badges={this.state.badges} isLoading={this.state.isLoading} />:null}
                </Container>
                </main>
                <Footer />
            </ThemeProvider>
        )
    }
}
export default withStyles(useStyles)(InventoryDetail);
