import React from 'react'
import { Route } from 'react-router-dom'

// const MasterRoute = (props) => {
//   const { isAuthenticated, component: Component, ...rest } = props
//   return (
//     <Route
//        {...rest}
//       render={props =>        
//           <Component isAuthenticated={isAuthenticated} {...props} />          
//       }
//     />
//   )
// }

const MasterRoute = ({ component: Component, isAuthenticated, user, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
          <Component user={user} isAuthenticated={isAuthenticated} {...props} />
      }
    />
  )
}

export default MasterRoute;
