import React from 'react'
import { Link as RouterLink ,useLocation } from 'react-router-dom'

import ListingGallery from '../ListingGallery/ListingGallery'
import ListingTabs from '../ListingTabs/ListingTabs'
import RequestInfo from '../ListingDetail/ActionForms/RequestInfo'
import ReferToFriend from '../ListingDetail/ActionForms/ReferToFriend'
//import Calculator from '../FinanceCalculator/FinanceCalculator'
import CalculatorHookForm from '../FinanceCalculator/CalculatorHookForm'
import TestDrive from '../ListingDetail/ActionForms/TestDrive'
import { CURRENCY } from '../../../utils'
import {BACKEND_URL} from '../../../utils'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import SendIcon from '@material-ui/icons/Send'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ForwardIcon from '@material-ui/icons/Forward'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import LinkIcon from '@material-ui/icons/Link'
import MailIcon from '@material-ui/icons/Mail'
import StoreIcon from '@material-ui/icons/Store'
import CardMedia from '@material-ui/core/CardMedia'
import FaceIcon from '@material-ui/icons/Face'
import PersonIcon from '@material-ui/icons/Person'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import vehicleDetailStyle from '../../../assets/jss/components/vehicleDetailStyle'

const useStyles = makeStyles(vehicleDetailStyle);

const LinkRouter = props => <Link {...props} component={RouterLink} />;

function ListingDetail(props){
    const location = useLocation();
    let x = location.pathname.split("/");
    
    const classes = useStyles();
    const vehicle = props.vehicle; 
    //console.log(vehicle);
    const prev = props.prev;
    const next = props.next;
    const isAuthenticated = props.isAuthenticated;
    const userDetail = props.userDetail;
    ///${url}/${slug}/${vehicle.id}/
    let pUrl,nUrl = null;
    if(prev && prev.title){
        const pSlug = prev.title.replace(/ /g, "-")+"-"+prev.stock_number.toLowerCase();
        pUrl = `/${x[1]}/${pSlug}/${prev.id}/`;

    } 
    if( next && next.title){
        const nSlug = next.title.replace(/ /g, "-")+"-"+next.stock_number.toLowerCase();

        nUrl = `/${x[1]}/${nSlug}/${next.id}/`;
    }
    

    const options = props.options;
    const optionCategories = props.optionCategories;
    const handleSubmitRequestInfo = props.handleSubmitRequestInfo;
    const handleSubmitTestDrive = props.handleSubmitTestDrive;
    const handleSubmitReferToFriend = props.handleSubmitReferToFriend;
    const openDialog = props.openDialog;
    const handleDialogOpen = props.handleDialogOpen;
    const handleDialogClose = props.handleDialogClose;
    const isLoading = props.isLoading;

    let dealerAddress = "";
    if(vehicle.location){
    dealerAddress += vehicle.location.address?vehicle.location.address:"";
    dealerAddress += vehicle.location.address2||vehicle.location.address2!==null?", "+vehicle.location.address2+",":"";
    dealerAddress += vehicle.location.city?"\n"+vehicle.location.city:"";
    dealerAddress += vehicle.location.province?", "+vehicle.location.province+",":"";
    dealerAddress += vehicle.location.postal_code?"\n"+vehicle.location.postal_code:"";
    }

    // useEffect(() => {

    //   }, [count]);

    return(
        <>
        
            <div className={`${classes.listingDetail} wr-listing-detail`}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={9}>
                        <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                            {vehicle.title}
                        </Typography>
                        <Typography component="h2" color="inherit" variant="h6" gutterBottom>
                            <Box fontStyle="italic">{vehicle.trim}</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={`${classes.price} wr-price`}>
                            {vehicle.price?<del><span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.price}</span></del>:null}
                            {vehicle.selling_price?<ins><span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.selling_price}</span></ins>:null}
                            {vehicle.price || vehicle.selling_price?<div className="wr-tax-info">Plus Sales Tax</div>:<div className="wr-request-price">Price on request</div>}
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={9}>
                        <div className = "wr-action-links">
                            <div className = {classes.actionButtons}>
                                {pUrl? <Button component={LinkRouter} to={pUrl} variant="outlined" size="small" color="primary" className={classes.button} startIcon={<NavigateBeforeIcon />}><span className="btn-txt">Prev</span></Button>:<Button variant="outlined" className={classes.button} size="small" startIcon={<NavigateBeforeIcon />} disabled><span className="btn-txt">Prev</span></Button>}
                                {!isAuthenticated?null:
                                <>
                                <Button onClick={() => handleDialogOpen("request-info")} variant="outlined" size="small" color="secondary" className={classes.button} endIcon={<SendIcon />}>Get More Info</Button>
                                <Button onClick={() => handleDialogOpen("test-drive")} variant="outlined" size="small" color="secondary" className={classes.button} endIcon={<EventAvailableIcon />}>Book Test Drive</Button>
                                <Button onClick={() => handleDialogOpen("refer-to-friend")}  variant="outlined" size="small" color="secondary" className={classes.button} endIcon={<ForwardIcon />}>Email to a Friend</Button>
                                </>
                                }
                                {nUrl? <Button component={LinkRouter} to={nUrl} variant="outlined" size="small" color="primary" className={classes.button} endIcon={<NavigateNextIcon />}><span className="btn-txt">Next</span></Button>:<Button variant="outlined" className={classes.button} size="small" endIcon={<NavigateNextIcon />} disabled><span className="btn-txt">Next</span></Button>}
                            </div>
                            {!isAuthenticated?null:
                            <>
                             <Dialog open={openDialog==="request-info"?true:false} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                               <RequestInfo user={props.user} vehicle_id={vehicle.id} handleDialogClose={handleDialogClose} handleSubmitRequestInfo={handleSubmitRequestInfo} isLoading={isLoading}/>
                            </Dialog>
                            <Dialog open={openDialog==="test-drive"?true:false} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                                <TestDrive user={props.user} vehicle_id={vehicle.id} handleDialogClose={handleDialogClose} handleSubmitTestDrive={handleSubmitTestDrive} isLoading={isLoading}/>
                            </Dialog>
                            <Dialog open={openDialog==="refer-to-friend"?true:false} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                                <ReferToFriend user={props.user} handleDialogClose={handleDialogClose} handleSubmitReferToFriend={handleSubmitReferToFriend} isLoading={isLoading}/>
                            </Dialog>
                            </>
                            }
                               
                        </div>
                        <Paper className={classes.paper}>
                            <div className="wr-vehicle-gallery">
                                <ListingGallery images={vehicle.images}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                    <Paper className={classes.paper}>
                    <table className="wr-attributes-col">
                        <tbody>
                            {vehicle.vehicle_info && vehicle.vehicle_info.year?<><tr><th>Year</th><td>{vehicle.vehicle_info.year}</td></tr></>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.make_id?<tr><th>Make</th><td>{vehicle.vehicle_info.make_id.make_name}</td></tr>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.model_id?<tr><th>Model</th><td>{vehicle.vehicle_info.model_id.model_name}</td></tr>:null}
                            {vehicle.attribute && vehicle.attribute.body_id?<><tr><th>Body</th><td>{vehicle.attribute.body_id.body_name}</td></tr></>:null}
                            {vehicle.kms?<><tr><th>Kms</th><td>{vehicle.kms}</td></tr></>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.enginesize_id?<><tr><th>Engine</th><td>{vehicle.vehicle_info.enginesize_id.enginesize_name}</td></tr></>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.transmission_id?<tr><th>Transmission</th><td>{vehicle.vehicle_info.transmission_id.transmission_name}</td></tr>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.drive_id?<><tr><th>Drivetrain</th><td>{vehicle.vehicle_info.drive_id.drive_name}</td></tr></>:null}
                            {vehicle.attribute && vehicle.attribute.exteriorcolor_id?<><tr><th>Exterior Color</th><td>{vehicle.attribute.exteriorcolor_id.color_name}</td></tr></>:null}
                            {vehicle.attribute && vehicle.attribute.interiorcolor_id?<><tr><th>Interior Color</th><td>{vehicle.attribute.interiorcolor_id.color_name}</td></tr></>:null}
                            {vehicle.vehicle_info && vehicle.vehicle_info.vin?<><tr className="wr-attribute wr-vin"><th>VIN</th><td>{vehicle.vehicle_info.vin}</td></tr></>:null}
                            {vehicle.stock_number?<><tr><th>Stock Number</th><td>{vehicle.stock_number}</td></tr></>:null}
                            {vehicle.attribute && vehicle.attribute.fuel_capacity?<><tr><th>Fuel Capacity</th><td>{vehicle.attribute.fuel_capacity+'L'}</td></tr></>:null}
                        </tbody>
                    </table>
                    </Paper>
                    <Paper className={`${classes.paper} ${classes.listedBy}`}>
                    
                        <Chip size="small" icon={vehicle.company && vehicle.company.name?<EmojiTransportationIcon />:<FaceIcon />} variant="outlined" label="Listed by" color="secondary" className={classes.listedByChip} />
                        <Typography component="h3" color="inherit" variant="h6" gutterBottom>
                        {vehicle.company && vehicle.company.name?vehicle.company.name:""} {!vehicle.company?"Private Owner":""}
                        </Typography>
                        {isAuthenticated?
                        <>
                            {vehicle.company?
                            <>
                            <List dense={true} className={classes.list}>
                            {vehicle.company.website && vehicle.company.website!=="null"?<ListItem disableGutters><ListItemIcon><LinkIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={vehicle.company.website} target="_blank">{vehicle.company.website}</Link>} /></ListItem>:null}
                            
                            {vehicle.company.contact_no?<ListItem disableGutters><ListItemIcon><PhoneIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"tel:"+ vehicle.company.contact_no}>{vehicle.company.contact_no}</Link>} /></ListItem>:null}
                            {vehicle.company.email?<ListItem disableGutters><ListItemIcon><MailIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"mailto:"+ vehicle.company.email}>{vehicle.company.email}</Link>} /></ListItem>:null}
                            </List>
                            {vehicle.company && vehicle.company.logo? <CardMedia className={classes.dealerLogo} image={`${BACKEND_URL}/storage/whrepo/logos/${vehicle.company.logo}`} title={vehicle.company && vehicle.company.name?vehicle.company.name:null} />:null}
                            </>
                            :
                            <List dense={true} className={classes.list}>
                                {vehicle.created_by?<ListItem disableGutters><ListItemIcon><PersonIcon fontSize="small" /></ListItemIcon><ListItemText primary={`${vehicle.created_by.first_name} ${vehicle.created_by.last_name}`} /></ListItem>:null}
                                {userDetail && userDetail.contact_no?<ListItem disableGutters><ListItemIcon><PhoneIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"tel:"+ userDetail.contact_no}>{userDetail.contact_no}</Link>} /></ListItem>:null}
                                {vehicle.created_by && vehicle.created_by.email?<ListItem disableGutters><ListItemIcon><MailIcon fontSize="small" /></ListItemIcon><ListItemText primary={<Link color="textSecondary" href={"mailto:"+ vehicle.created_by.email}>{vehicle.created_by.email}</Link>} /></ListItem>:null}
                            </List>
                            }
                            {vehicle.location?
                            <List dense={true} className={classes.list}>
                            {vehicle.location.location_name?<ListItem disableGutters><ListItemIcon><StoreIcon fontSize="small" /></ListItemIcon><ListItemText primary={vehicle.location.location_name} /></ListItem>:null}
                            {dealerAddress?<ListItem className={classes.dealerAddress} disableGutters><ListItemIcon><LocationOnIcon fontSize="small" /></ListItemIcon><ListItemText primary={dealerAddress}  className={classes.dealerAddress}/></ListItem>:null}
                            </List>
                            :null}
                        </>
                        :null}
                        {!isAuthenticated?
                        <>
                        <br />
                        <Typography variant="body2"><Link color="secondary" href="/login">Log in</Link> or <Link color="secondary" href="/register">Register</Link><br />to view contact information.</Typography>
                        </>
                        :null}
                        
                    </Paper>
                    </Grid>
                </Grid>
                
                
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={9}>
                        <Paper>
                            <div className={`${classes.vehicleInfoTabs} wr-vehicle-info-tabs`}>
                                <ListingTabs vehicle={vehicle} options={options} optionCategories={optionCategories}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Paper className={`${classes.paper} ${classes.financeCalc}`}>
                        <Typography component="h3" color="inherit" variant="h6" gutterBottom>Finance calculator</Typography>
                        {/* <Calculator />  */}
                        <CalculatorHookForm />
                        </Paper>
                    </Grid>
                </Grid>
                
            </div>
        </>
    )
}

export default ListingDetail;