

const headerStyle = {
    appBar: {
        backgroundColor: "#fff",
        
        //color: "#555",
    },
    toolBar :{
        justifyContent: "space-between",
        padding: "0"
    },
    navBar: {
        display: "flex"
    },
    brandLogo: {
        //display: "inline-flex",
        backgroundColor: "black",
       // flex: "1"

    }
};

export default headerStyle;