const teaserTabStyle = theme => ({
    homeRow:{
        marginBottom: "32px",
    },
    link:{
        display: "flex",
        color: theme.palette.primary.main,
        "& :hover":{
            color: theme.palette.secondary.main,
            "& .MuiChip-labelSmall:hover":{
                color:"rgba(0, 0, 0, 0.87)"
            }
        },
        "& .MuiBadge-colorSecondary":{
            color: "#ffffff",
        },
        
    },
    Tabs: {
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        "& .MuiTabs-root":{
            overflow:"visible",
            "& .MuiTabs-fixed":{
                backgroundColor: theme.palette.background.paper,
                borderTopLeftRadius:4,
                borderTopRightRadius:4,
                //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                boxShadow: "0px 2px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",                width:"inherit",
                flexGrow:0,
            }
        },
        "& .MuiTab-root":{
            [theme.breakpoints.up('xs')]: {
                minWidth:125,
            },
        },
        
        "& .MuiBox-root":{
            position:"relative",
            zIndex:1,
            backgroundColor: theme.palette.background.paper,
            borderRadius:4,
            borderTopLeftRadius:0,
            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
        "& .Mui-selected":{
            color:theme.palette.secondary.main,
        }
      },
      chipCount:{
        minWidth:theme.spacing(4),
       },
});

  export default teaserTabStyle;