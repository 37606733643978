// Header
import React from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container";

import DesktopMenu from '../../components/Header/DesktopMenu';

import AccountMenu from '../../components/Header/AccountMenu';
import MobileMenuDrawer from '../../components/Header/MobileMenu'

import styles from '../../assets/jss/components/headerStyle.js'
const useStyles = makeStyles(styles);


const Header = (props) => {
    const classes = useStyles();
   
    return(
            <>
                <AppBar className={classes.appBar}>
                    <Container>
                        <Toolbar className={classes.toolBar}>
                        <NavLink to="/" className={classes.brandLogo}><img src="/images/whrepo-temp-logo.jpg" alt="Wheels Repository" /></NavLink>
                        <div className="wr-nav-wrapper">
                            <DesktopMenu isAuthenticated={props.isAuthenticated}/>
                            <div className="wr-nav-right-wrapper">
                                {props.isAuthenticated?<AccountMenu user={props.user}/>:null }
                                <MobileMenuDrawer isAuthenticated={props.isAuthenticated}/>
                            </div>
                        </div>
                        
                        </Toolbar>
                    </Container>
                </AppBar>
            </>
    )
}
export default Header;