import React from 'react'

import ContactSEO from '../../components/SEO/Contact'
import Header from '../../components/Header/Header'
import SimpleBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import {ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

const useStyles = makeStyles(theme => ({
    main:{
        marginTop: 100,
        minHeight: "calc(100vh - 341px)",
    },
    paper: {
        padding: theme.spacing(1, 2),
        marginBottom: '20px'
      },
    textCenter:{
        textAlign:"center"
    }
}));

const Contact = (props) => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <ThemeProvider theme={whrepoTheme}>
            <ContactSEO />
            <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
            <main className={classes.main}>
                <Container>
                    <SimpleBreadcrumbs />
                    <Typography component="h1" color="inherit" variant="h3" gutterBottom>
                        Contact
                    </Typography>
                    
                    <Typography variant="body1">For queries, please email at <Link color="secondary" href="mailto:contact@whrepo.com">contact[at]whrepo.com</Link></Typography>
                    <Typography variant="body1">For support, please email at <Link color="secondary" href="mailto:support@whrepo.com">support[at]whrepo.com</Link></Typography>
    
                </Container>
            </main>
            <Footer />
        </ThemeProvider>
    )
}

export default Contact;