import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
//import "@babel/polyfill";
import ReactDOM from 'react-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './assets/scss/whrepo-frontend.scss';

import Main from './components/Main'
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
