import React from 'react'
import { Helmet } from 'react-helmet'

export default function UsedVehiclesSEO() {
  return (
        <Helmet>
            <title>Used Vehicles &#8226; Second-hand Cars from all over Nepal &#8226; Wheels Repository</title>
            <meta name="description" content="Browse used vehicles, second-hand car prices, including private sales by owner, refine and analyze to find the best deals in the city near you." />
            <link rel="canonical" href="https://whrepo.com/used-vehicles/" />
        </Helmet>
  )
}

