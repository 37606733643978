import React from 'react'
import { Helmet } from 'react-helmet'

export default function DealerListingSEO() {
  return (
        <Helmet>
            <title>Vehicle Dealers, Car Showrooms in Nepal &#8226; Wheels Repository</title>
            <meta name="description" content="Find a local vehicle dealers/car showrooms near you easily at whrepo.com." />
            <link rel="canonical" href="https://whrepo.com/dealers/" />
        </Helmet>
  )
}

