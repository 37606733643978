import React from 'react'
import { Helmet } from 'react-helmet'

export default function TermsSEO() {
  return (
    <>
        <Helmet>
        <title>Terms &amp; Conditions &#8226; Wheels Repository  </title>
        <meta name="description" content="Terms &amp; Conditions - whrepo.com" />
        <link rel="canonical" href="https://whrepo.com/terms/" />
        </Helmet>
    </>
  )
}

